import styled from 'styled-components';

import {colors} from '../../views.constants';
import {
  TableCell,
  TableHeadingCell,
} from '../settings.styled';

const GeneratePayloadContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 40px 0 40px 0;
`;

const TableContainer = styled.div`
  margin-bottom: 40px;
`;

const TableHeading = styled.div`
  display: flex;

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 20px 13px 4px;
  }
`;

const CombinedReportsWarning = styled.div`
  display: flex;
  color: ${colors.RED_BRIGHT};
  p {
    margin-left: 4px;
  }
`;

const PayloadSettingTableCell = styled(TableCell)`
  &:nth-child(4n+1) {
    border-left-width: 1px;
    padding-left: 12px;
  }
  &:nth-child(4n) {
    border-right-width: 1px;
    padding-right: 12px;
    justify-content: flex-end;
  }
`;

const PayloadReportTableCell = styled(TableCell)`
  &:nth-child(3n+1) {
    border-left-width: 1px;
    padding-left: 12px;
  }
  &:nth-child(3n) {
    border-right-width: 1px;
    padding-right: 12px;
    justify-content: flex-end;
  }
`;

const PayloadTableHeadingCell = styled(TableHeadingCell)`
  &:nth-child(4n+1) {
    padding-left: 12px;
    border-left-width: 1px;
    border-radius: 4px 0 0 0;
  }
  &:nth-child(4n) {
    border-right-width: 1px;
    border-radius: 0 4px 0 0;
    justify-content: flex-start;
  }
`;

const PayloadContainer = styled.div`
  margin-bottom: 40px;
  padding-bottom: 0px;
`;

const PayloadHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 13px 4px;
`;

const PayloadBox = styled.div`
  width: calc(100%-30px);
  min-height: 30px;
  background: ${colors.GREY_02};
  padding: 20px 0;
  border: 1px solid ${colors.GREY_15}
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 40px;
`;

const PayloadText = styled.div`
  flex-grow: 1;
  width: 100px;
  padding-right: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
  overflow-wrap: break-word;
`;

const CopyButton = styled.div`
  width: 30px;
  cursor: pointer;
  flex-shrink: 0;
`;

const PayloadBase64Container = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

const PayloadBase64Text = styled.div`
  font-size: 12px;
  margin-right: 3px;
`;

const NoPayloadNote = styled.div`
  text-align: center;
  margin-top: 50px;
  color: ${colors.GREY_27}
`;

const ResetContainer = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.GREY_15};
  display: flex;
  align-items: center;
`;

const ResetNote = styled.div`
  margin-left: 15px;
`;

export {
  GeneratePayloadContainer,
  PayloadContainer,
  PayloadHeading,
  TableContainer,
  TableHeading,
  CombinedReportsWarning,
  PayloadReportTableCell,
  PayloadSettingTableCell,
  PayloadTableHeadingCell,
  PayloadBox,
  PayloadText,
  CopyButton,
  PayloadBase64Container,
  PayloadBase64Text,
  NoPayloadNote,
  ResetContainer,
  ResetNote,
};
