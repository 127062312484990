// Copyright (C) 2021, Sensative AB (www.sensative.com)

import React from 'react';
import _ from 'lodash';

import {reports} from '../static-data';
import {colors} from '../../views.constants';
import {
  ENABLE_REPORT_EXPLANATION,
  COMBINED_REPORTS_EXPLANATION,
  REPORT_TYPES,
  STEPS,
} from '../settings.constants';
import {
  EnableReportsContainer,
  ReportsTableCell,
  ColumnReportsTableCell,
  ReportsTableHeadingCell,
  TableReportName,
  TableReportDescription,
  StyledCheckbox,
} from './enable-reports.styled';
import {
  StyledTable,
  StyledLink,
  InfoBox,
  Tooltip,
  Button,
} from '../settings.styled';
import { Container } from '../select-device/select-device.styled';
import { ButtonsContainer } from '../profiles/profiles.styled';

import {ReportsTableSection} from '../generate-payload';


const EnableReports = props => {

  const getTooltip = tooltip => {
    return tooltip;
  }
  const onToggleReportClick = (reportType, report) => {
    props.toggleReport(reportType, report, props.selectedDevice);
  };

  const Buttons = () => (
    <ButtonsContainer>
      {
        <Button onClick={() => props.setSelectedStep(STEPS.SETTINGS)}>Proceed</Button>
      }
    </ButtonsContainer>
  );

  const no_enabled_cr1 = 0 === _.size(_.intersection(props.addedReports.enabledReports, props.addedReports.combinedReportsOne));
  const no_enabled_cr2 = 0 === _.size(_.intersection(props.addedReports.enabledReports, props.addedReports.combinedReportsTwo));
  const no_enabled_cr3 = 0 === _.size(_.intersection(props.addedReports.enabledReports, props.addedReports.combinedReportsThree));

  return (
    <EnableReportsContainer>
      {props.showAdvanced || <Container>
      {props.numAddedReports === 0 && <InfoBox>No reports enabled.
        Selecting a <StyledLink onClick={()=>props.setSelectedStep(STEPS.PROFILES)} to="/profiles">Profile</StyledLink> may get you started quickly.</InfoBox>}
      <ReportsTableSection
        setSelectedStep={props.setSelectedStep}
        addedReports={props.addedReports}
        reports={props.addedReports}
        selectedDevice={props.selectedDevice}
        toggleReport={()=>{}}
        showWarning={false}
        usedProfile={props.usedProfile}
        showAdvanced={props.showAdvanced}
      />
      <Buttons/>
      </Container>}

      <div style={{marginTop:8, padding:16, backgroundColor:colors.GREY_02}}>
      <h4>Modify or Add Reports</h4>

      <InfoBox>
        <p>Modifying or adding reports to others than in the selected profile may significantly affect product battery life and performance.</p>
        <hr/>
        <small><table>
          <tbody><tr><td width="170"><b>Enabled</b></td><td>{ENABLE_REPORT_EXPLANATION}</td></tr>
                      <tr><td width="170"><b>Confirmed</b></td><td>Selecting Confirm will make the unit send information in Confirmed uplinks, and also re-send if no confirmation was received from server.<br></br>
                       <small>Note: This feature is available from software release R12 (Production July 2022 or later). In previous versions only Alarms are confirmed.</small></td></tr>
                      <tr><td width="170"><b>RG (Report Group)</b></td><td>{COMBINED_REPORTS_EXPLANATION}</td></tr>
                      </tbody></table></small>
      </InfoBox>
      {<table style={{paddingBottom:'10pt'}}><tbody >
        <tr><td><StyledCheckbox size={15} color={colors.DARK_GREEN}
                                checked={props.showAdvanced}
                                onClick={() => props.setShowAdvanced(!props.showAdvanced)}/></td>
                                <td>I understand the above. Show advanced report options.</td></tr>
      </tbody></table>}

      {props.showAdvanced && <StyledTable gridTemplateColumns={'66px 66px 26px 26px 26px 3fr'}>
        <ReportsTableHeadingCell>Enabled</ReportsTableHeadingCell>
        <ReportsTableHeadingCell>Confirmed</ReportsTableHeadingCell>
        <ReportsTableHeadingCell>RG1</ReportsTableHeadingCell>
        <ReportsTableHeadingCell>RG2</ReportsTableHeadingCell>
        <ReportsTableHeadingCell>RG3</ReportsTableHeadingCell>
        <ReportsTableHeadingCell>Report</ReportsTableHeadingCell>

        {_.map(reports[props.selectedDevice], (report, key) => {
          const enabled = _.includes(props.addedReports.enabledReports, key);
          const enabled_cr1 = _.includes(props.addedReports.combinedReportsOne, key);
          const enabled_cr2 = _.includes(props.addedReports.combinedReportsTwo, key);
          const enabled_cr3 = _.includes(props.addedReports.combinedReportsThree, key);
          const enabled_confirm = _.includes(props.addedReports.confirmedReports, key);

          // Ensure that CRx cannot be set unless some other CRx is set OR this report is enabled.
          let disable_cr1 = !(enabled || enabled_cr1 || (_.size(props.addedReports.combinedReportsOne) > 0 &&!no_enabled_cr1));
          let disable_cr2 = !(enabled || enabled_cr2 || (_.size(props.addedReports.combinedReportsTwo) > 0 &&!no_enabled_cr2));
          let disable_cr3 = !(enabled || enabled_cr3 || (_.size(props.addedReports.combinedReportsThree) > 0 &&!no_enabled_cr3));
          let disable_confirm = !enabled;

          // Check if we should show a warning
          const warn_cr1 = enabled_cr1 && (_.size(props.addedReports.combinedReportsOne)   === 1);
          const warn_cr2 = enabled_cr2 && (_.size(props.addedReports.combinedReportsTwo)   === 1);
          const warn_cr3 = enabled_cr3 && (_.size(props.addedReports.combinedReportsThree) === 1);
          const warn_confirm = enabled && !enabled_confirm && report.name.includes("Alarm");

          // Check if no active report among the enabled combined reports, this is an error
          let err_cr1 = enabled_cr1 && no_enabled_cr1;
          let err_cr2 = enabled_cr2 && no_enabled_cr2;
          let err_cr3 = enabled_cr3 && no_enabled_cr3;

          const onlyCombined = _.has(report, 'onlyCombined') && (report.onlyCombined === true);
          const mustDrive = _.has(report, "stateful") && (report.stateful === true);
          if (mustDrive && !enabled) {
            disable_cr1 = !enabled_cr1;
            disable_cr2 = !enabled_cr2;
            disable_cr3 = !enabled_cr3;
            err_cr1 = enabled_cr1; // Will indicate wrong error?
            err_cr2 = enabled_cr2; 
            err_cr3 = enabled_cr3;
          }

          return <React.Fragment key={key}>
            <ReportsTableCell paddingLeft={10}>
              {onlyCombined || <Tooltip title={getTooltip("Enable")} placement="top" enterDelay={500}>
              <StyledCheckbox
                  size={15}
                  color={colors.MEDIUM_GREEN}
                  checked={enabled}
                  disabled={onlyCombined}
                  onClick={() => onToggleReportClick(REPORT_TYPES.enabledReports.name, key)}
                />
              </Tooltip>}
            </ReportsTableCell>
            <ReportsTableCell>
              { disable_confirm || 
                <Tooltip title={getTooltip(warn_confirm?'Disabled confirm for alarm' : (enabled_confirm?'Confirmed uplink':'Unconfirmed uplink'))} placement="top" enterDelay={500}>
                  <StyledCheckbox
                    warn={warn_confirm}
                    size={11}
                    color={colors.MEDIUM_GREEN}
                    checked={enabled_confirm}
                    onClick={() => onToggleReportClick(REPORT_TYPES.confirmedReports.name, key)}
                  />
              </Tooltip>}
            </ReportsTableCell>
            <ReportsTableCell>
              {disable_cr1 || 
                <Tooltip title={getTooltip(warn_cr1?'Only this report added to RG1' : (err_cr1 ? 'Aggregated report not enabled in RG1' : 'Report Group 1'))} placement="top" enterDelay={500}>
                  <StyledCheckbox
                    warn={warn_cr1 || err_cr1}
                    size={11}
                    color={colors.MEDIUM_GREEN}
                    checked={enabled_cr1}
                    onClick={() => onToggleReportClick(REPORT_TYPES.combinedReportsOne.name, key)}
                  />
                </Tooltip>}
            </ReportsTableCell>
            <ReportsTableCell>
            {disable_cr2 || 
              <Tooltip title={getTooltip(warn_cr2?'Only this report added to RG2' : (err_cr2 ? 'Aggregated report not enabled in RG2' : 'Report Group 2'))} placement="top" enterDelay={500}>
                <StyledCheckbox
                  warn={warn_cr2 || err_cr2}
                  size={11}
                  color={colors.MEDIUM_GREEN}
                  checked={enabled_cr2}
                  onClick={() => onToggleReportClick(REPORT_TYPES.combinedReportsTwo.name, key)}
                />
              </Tooltip>}
            </ReportsTableCell>
            <ReportsTableCell>
              { disable_cr3 || 
                <Tooltip title={getTooltip(warn_cr3?'Only this report added to RG3' : (err_cr3 ? 'Aggregated report not enabled in RG3' : 'Report Group 3'))} placement="top" enterDelay={500}>
                  <StyledCheckbox
                    warn={warn_cr3 || err_cr3}
                    size={11}
                    color={colors.MEDIUM_GREEN}
                    checked={enabled_cr3}
                    onClick={() => onToggleReportClick(REPORT_TYPES.combinedReportsThree.name, key)}
                  />
              </Tooltip>}
            </ReportsTableCell>
            <ColumnReportsTableCell>
              <TableReportName>{report.name}</TableReportName>
              <TableReportDescription>{report.description}</TableReportDescription>
            </ColumnReportsTableCell>
          </React.Fragment>}
        )}
      </StyledTable>}
      </div>
      { props.numAddedReports !== 0 && props.showAdvanced && <Buttons/>}
    </EnableReportsContainer>
  );
};

export default EnableReports;
