// Copyright (C) 2021, Sensative AB (www.sensative.com)

import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import Settings from './settings.view';
import {Wrapper} from './views.styled';
import NavBar from './navbar.view';
import Decode from './decode.view';
import routes from './views.routes';

const Views = () => (
  <Wrapper>
    <Router>
      <NavBar />
      <Decode />
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.PROFILES} />
        </Route>
        <Route path={'/'} component={Settings} />
      </Switch>
    </Router>
  </Wrapper>
);

export default Views;
