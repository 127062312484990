import React from 'react';
import _ from 'lodash';
import {Icon} from 'react-icons-kit'

import routes from '../../../views.routes';
import {colors} from '../../../views.constants';
import {RELATED_REPORTS_TOOLTIP, STEPS} from '../../settings.constants';
import {
  SettingContainer,
  SettingHeader,
  SettingDescriptionHeading,
  SettingDescription,
  SettingUnitHeader,
  SettingDefaultValueHeader,
  SettingAllowedValuesHeader,
  SettingRelatedReportsHeader,
  SettingRelatedReports,
  SettingUnit,
  SettingDefaultValue,
  SettingAllowedValues,
  SettingNewValueHeading,
  SettingNewValue,
  SettingButton,
  AddedSettingItems,
} from './setting-info.styled';
import {
  Input,
  Button,
  GhostedButton,
  StyledLink,
  TooltipBadge,
  Tooltip,
} from '../../settings.styled';

const SettingInfo = props => {
  const onAddSetting = () => {
    props.addSetting(props.selectedSetting, props.newValue, props.selectedDevice);
    props.selectSetting(null, null); // Hide this
  };
  const getDisabledTooltip = () => {
    return null;
  };
  return (
    <div style={{position:"fixed", margin:30, borderRadius:20, color:colors.DARK_GREEN, borderWidth:"2px", padding:16, backgroundColor:colors.GREY_1}}>
    <SettingContainer>
      <SettingHeader>
        <Icon icon={props.selectedSetting.category.icon} />
        <h3>{props.selectedSetting.name}</h3>
      </SettingHeader>
      <SettingDescriptionHeading>
        <h4>Description</h4>
      </SettingDescriptionHeading>
      <SettingDescription>
        {props.selectedSetting.description}
      </SettingDescription>
      <SettingUnitHeader>
        <h4>Unit</h4>
      </SettingUnitHeader>
      <SettingDefaultValueHeader>
        <h4>Default value</h4>
      </SettingDefaultValueHeader>
      <SettingAllowedValuesHeader>
        <h4>Allowed values</h4>
      </SettingAllowedValuesHeader>
      <SettingRelatedReportsHeader>
        <h4>Related reports</h4>
        <Tooltip title={RELATED_REPORTS_TOOLTIP} placement="right">
          <TooltipBadge>!</TooltipBadge>
        </Tooltip>
      </SettingRelatedReportsHeader>
      <SettingUnit>
        <p>{props.selectedSetting.unit.fullName}</p>
      </SettingUnit>
      <SettingDefaultValue>
        <p>{props.selectedSetting.default}</p>
      </SettingDefaultValue>
      <SettingAllowedValues>
        <p>{props.selectedSetting.allowedValuesText}</p>
      </SettingAllowedValues>
      <SettingRelatedReports>
        {_.isEmpty(props.selectedSetting.relatedReports) ? (
          <p>None</p>
        ) : (
          _.map(props.selectedSetting.relatedReports, report =>
            <StyledLink to={routes.REPORTS} key={report} onClick={()=>props.setSelectedStep(STEPS.REPORTS)}>
              <p key={report}>- {report}</p>
            </StyledLink>
          )
        )}
      </SettingRelatedReports>
      <SettingNewValueHeading>
        <h4>New value</h4>
      </SettingNewValueHeading>
      <SettingNewValue>
        <Tooltip title={getDisabledTooltip()} placement="left">
          <Input
            onChange={evt => {
              props.updateNewValue(evt.target.value);
            }}
            value={props.newValue}
          />
        </Tooltip>
      {props.validationError &&
        <p>{props.validationError}</p>
      }
      </SettingNewValue>
      <SettingButton>
        { _.reduce(props.addedSettings, (p, s) => {
            return (s.setting.id === props.selectedSetting.id) ? false : p}, true) ? (
          <AddedSettingItems>
          <Tooltip title={getDisabledTooltip()} placement="top">
            <Button
              onClick={() => onAddSetting()}
              disabled={!props.newValue || props.validationError !== ''}
            >
              Add Setting
            </Button>
          </Tooltip>
            <Button
            onClick={() => props.selectSetting(null, null)}
          >Cancel</Button>
          </AddedSettingItems>
        ) : (
          <AddedSettingItems>
            <Button
              onClick={() => onAddSetting()}
              disabled={!props.newValue || props.validationError !== ''}
            >
              Update Setting
            </Button>
              {props.removeSetting && props.showAdvanced &&
              <GhostedButton
                background={colors.RED}
                onClick={() => { props.removeSetting(props.selectedSetting, props.selectedDevice); props.selectSetting(null, null);}}
              >
                Remove setting
              </GhostedButton>}
            <Button
              onClick={() => props.selectSetting(null, null)}
            >Cancel</Button>
          </AddedSettingItems>
        )}
      </SettingButton>
    </SettingContainer>
    </div>
  );
};

export default SettingInfo;
