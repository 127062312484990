import React from 'react';

import SelectSetting from './select-setting';

const AddSettings = props => {
  return <React.Fragment>
    <SelectSetting
      setSelectedStep={props.setSelectedStep}
      selectedDevice={props.selectedDevice}
      selectedCategory={props.selectedCategory}
      selectedSetting={props.selectedSetting}
      selectCategory={props.selectCategory}
      selectSetting={props.selectSetting}
      addedSettings={props.addedSettings}
      addedReports={props.addedReports}
      numAddedReports={props.numAddedReports}
      usedProfile={props.usedProfile}
      showAdvanced={props.showAdvanced}
      setShowAdvanced={props.setShowAdvanced}
      />
  </React.Fragment>
};

export default AddSettings;
