// Copyright (C) 2021, Sensative AB (www.sensative.com)

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { actions as settingActions } from '../../state/settings.redux';
import routes from '../views.routes';
import AddSettings from './add-settings';
import SettingInfo from './add-settings/setting-info';
import EnableReports from './enable-reports';
import GeneratePayload from './generate-payload';
import MenuBar from './menu-bar';
import Profiles from './profiles';
import SelectDevice from './select-device';
import { actions as viewActions } from './settings.redux';
import {Container} from './select-device/select-device.styled';

import {
  selectAddedReportsForSelectedDevice, selectAddedSettingsForSelectedDevice,
  selectCombinedReportsWarnings, selectNumAddedReports, selectPayload,
  selectProfilesSelectOptions,
  selectSelectedProfileData,
  selectSelectedProfileOption, selectSelectedSetting,
  selectUsedProfile, selectValidationError
} from './settings.selectors';
import { SettingsWrapper } from './settings.styled';
import { STEPS } from './settings.constants'

function SettingDialogIfRequired(props) {
  if (props.selectedDevice && props.selectedCategory && props.selectedSetting)
    return <SettingInfo
      setSelectedStep={props.setSelectedStep}
      selectedDevice={props.selectedDevice}
      selectedSetting={props.selectedSetting}
      selectSetting={props.selectSetting}
      newValue={props.newValue}
      validationError={props.validationError}
      addSetting={props.addSetting}
      removeSetting={props.removeSetting}
      updateNewValue={props.updateNewValue}
      addedSettings={props.addedSettings}
      enableReport={props.enableReport}
      enabledReports={props.enabledReports}
      usedProfile={props.usedProfile}
      showAdvanced={props.showAdvanced}
    />
  else
    return <div></div>
}

const renderAddSettings = props => (
  <React.Fragment>
  <AddSettings
    setSelectedStep={props.setSelectedStep}
    selectCategory={props.selectCategory}
    selectSetting={props.selectSetting}
    updateNewValue={props.updateNewValue}
    addSetting={props.addSetting}
    removeSetting={props.removeSetting}
    selectedDevice={props.selectedDevice}
    selectedCategory={props.selectedCategory}
    selectedSetting={props.selectedSetting}
    newValue={props.newValue}
    validationError={props.validationError}
    addedSettings={props.addedSettings}
    enableReport={props.enableReport}
    enabledReports={props.enabledReports}
    usedProfile={props.usedProfile}
    numAddedReports={props.numAddedReports}
    addedReports={props.addedReports}
    setShowAdvanced={props.setShowAdvanced}
    showAdvanced={props.showAdvanced}
  />
  {SettingDialogIfRequired(props)}
  </React.Fragment>
);

const renderEnabledReports = props => (
  <React.Fragment>
  <EnableReports
    setSelectedStep={props.setSelectedStep}
    toggleReport={props.toggleReport}
    enabledReports={props.enabledReports}
    selectedDevice={props.selectedDevice}
    addedReports={props.addedReports}
    addedSettings={props.addedSettings}
    selectCategory={props.selectCategory}
    selectSetting={props.selectSetting}
    usedProfile={props.usedProfile}
    numAddedReports={props.numAddedReports}
    setShowAdvanced={props.setShowAdvanced}
    showAdvanced={props.showAdvanced}
  />
  {SettingDialogIfRequired(props)}
  </React.Fragment>
);

const renderProfiles = props => (
  <React.Fragment>
    <SelectDevice
        selectedDevice={props.selectedDevice}
        selectDevice={props.selectDevice}
      />
      {props.selectedDevice && <Container>
      <Profiles
        setSelectedStep={props.setSelectedStep}
        selectedDevice={props.selectedDevice}
        selectProfile={props.selectProfile}
        profileOptions={props.profileOptions}
        selectedProfileData={props.selectedProfileData}
        selectedProfileOption={props.selectedProfileOption}
        useProfile={props.useProfile}
        usedProfile={props.usedProfile}
        numAddedReports={props.numAddedReports}
        addedSettings={props.addedSettings}
        setAdvancedMode={(b)=>{props.setShowAdvanced(b);}}
        reset={props.reset}
      />
    </Container> }
  </React.Fragment>
);

const renderGeneratePayload = props => (
  <GeneratePayload
    setSelectedStep={props.setSelectedStep}
    addedSettings={props.addedSettings}
    removeSetting={props.removeSetting}
    selectedDevice={props.selectedDevice}
    addedReports={props.addedReports}
    toggleReport={props.toggleReport}
    payload={props.payload}
    base64encodingIsEnabled={props.base64encodingIsEnabled}
    toggleBase64Encoding={props.toggleBase64Encoding}
    selectCategory={props.selectCategory}
    selectSetting={props.selectSetting}
    combinedReportsWarnings={props.combinedReportsWarnings}
    usedProfile={props.usedProfile}
    showAdvanced={props.showAdvanced}
    reset={props.reset}
  />
);

const Copyright = props => {
    return <div style={{position:"fixed", bottom:0}}>
        <center><small>Copyright (C) 2021, Sensative AB. Version: {process.env.REACT_APP_VERSION}</small></center>
        </div>;
}

const Settings = props => {
  const route = useLocation().pathname;
  if ((!STEPS.routeToStep.hasOwnProperty(route)) || STEPS.routeToStep[route] !== props.selectedStep)
    return <Redirect to={STEPS.stepToRoute[props.selectedStep]}/>;

  return <SettingsWrapper>
    <>
    <MenuBar
      addedSettings={props.addedSettings}
      setSelectedStep={props.setSelectedStep}
      numAddedReports={props.numAddedReports}
      selectedDevice={props.selectedDevice}
      usedProfile={props.usedProfile}
    />
    <Switch>
      <Route exact path={routes.PROFILES} render={() => renderProfiles(props)        } />
      <Route exact path={routes.SETTINGS} render={() => renderAddSettings(props)     } />
      <Route exact path={routes.REPORTS} render={()  => renderEnabledReports(props)  } />
      <Route exact path={routes.PAYLOAD} render={()  => renderGeneratePayload(props) } />
    </Switch>
    </>
    <Copyright></Copyright>
  </SettingsWrapper>
};

const mapStateToProps = state => ({
  selectedStep: state.views.settings.selectedStep,
  selectedDevice: state.views.settings.selectedDevice,
  selectedCategory: state.views.settings.selectedCategory,
  selectedSetting: selectSelectedSetting(state),
  selectedProfileData: selectSelectedProfileData(state),
  selectedProfileOption: selectSelectedProfileOption(state),
  newValue: state.views.settings.newValue,
  validationError: selectValidationError(state),
  addedSettings: selectAddedSettingsForSelectedDevice(state),
  addedReports: selectAddedReportsForSelectedDevice(state),
  payload: selectPayload(state),
  numAddedReports: selectNumAddedReports(state),
  base64encodingIsEnabled: state.views.settings.base64encodingIsEnabled,
  showAdvanced: state.views.settings.showAdvanced,
  combinedReportsWarnings: selectCombinedReportsWarnings(state),
  profileOptions: selectProfilesSelectOptions(state),
  usedProfile: selectUsedProfile(state),
});

const mapDispatchToProps = dispatch => ({
  setSelectedStep: step => (
    dispatch(viewActions.setSelectedStep(step))
  ),
  selectDevice: device => (
    dispatch(viewActions.selectDevice(device))
  ),
  selectCategory: category => (
    dispatch(viewActions.selectCategory(category))
  ),
  selectSetting: (setting, addedSettings) => (
    dispatch(viewActions.selectSetting(setting, addedSettings))
  ),
  selectProfile: profile => (
    dispatch(viewActions.selectProfile(profile))
  ),
  updateNewValue: value => (
    dispatch(viewActions.updateNewValue(value))
  ),
  toggleBase64Encoding: () => (
    dispatch(viewActions.toggleBase64Encoding())
  ),
  setShowAdvanced: (b) => {
    dispatch(viewActions.setShowAdvanced(b))
  },
  addSetting: (setting, newValue, selectedDevice) => (
    dispatch(settingActions.addSetting(setting, newValue, selectedDevice))
  ),
  removeSetting: (setting, selectedDevice) => (
    dispatch(settingActions.removeSetting(setting, selectedDevice))
  ),
  enableReport: (name, selectedDevice) => (
    dispatch(settingActions.enableReport(name, selectedDevice))
  ),
  toggleReport: (reportType, reportName, selectedDevice) => (
    dispatch(settingActions.toggleReport(reportType, reportName, selectedDevice))
  ),
  useProfile: (profile, selectedDevice) => (
    dispatch(settingActions.useProfile(profile, selectedDevice))
  ),
  reset: selectedDevice => (
    dispatch(settingActions.reset(selectedDevice))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
