import React from 'react';

import {DecodeBox, DecodeBoxClosed, DecodeBoxTopBar, DecodeBoxBottomBar} from './decode.styled';
import {decodeLoraStripsUplink, decodeLoraStripsDownlink} from 'strips-lora-translator-open-source/strips-translate';
import {Icon} from 'react-icons-kit';
import {ic_close as closeIcon} from 'react-icons-kit/md/ic_close'
import {ic_open_in_browser as openIcon} from 'react-icons-kit/md/ic_open_in_browser'
import {ic_content_copy as copyIcon} from 'react-icons-kit/md/ic_content_copy'
import {toast} from 'react-toastify';
 
const decodeup = decodeLoraStripsUplink;
const decodedown = decodeLoraStripsDownlink;

const modes = ['auto', 'hex', 'base64'];

const Decode = props => {
    const title = "Strips Payload Decoder";

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState('');
    const [port, setPort] = React.useState(1);
    const [mode, setMode] = React.useState(modes[0]);
    const [direction, setDirection] = React.useState("up");
    const [openTime, setOpenTime] = React.useState(new Date().getTime());

    var usemode = mode;
    const trimmed = data.replace(/\s/g, "");

    // check if we can decode input as hex, convert it back to SAME hex => in that case we use hex
    try {
        if (usemode === "auto")
            usemode = Buffer.from(trimmed, "hex").toString("hex").toLowerCase() === trimmed.toLowerCase() ? "hex" : "base64";
    } catch (e) {usemode = "base64"}

    let decoded = "";
    try {
        const buffer = Buffer.from(trimmed, usemode);
        if (buffer.toString(usemode).toLowerCase() !== trimmed.toLowerCase())
            decoded = "Bad input format";
        else
            decoded = direction === "up" ? decodeup(port, buffer) : decodedown(port, buffer);
    } catch (e) { decoded = e.message; }

    if (!open)
        return (
            <DecodeBoxClosed onClick={()=>{setOpen(true); setOpenTime(new Date().getTime())}}>
                <Icon style={{margin:3}} icon={openIcon} /><div style={{paddingTop:3, marginRight:6}}>{title}</div>
            </DecodeBoxClosed>
        );

    const onCopyPayload = () => {
        navigator.clipboard.writeText(JSON.stringify(decoded));
        toast.info('Decoded payload copied to clipboard (JSON format)');
    };
        
    return (
        <DecodeBox>
            <DecodeBoxTopBar onClick={()=>setOpen(false)}><Icon style={{margin:3}} icon={closeIcon} />
            <div style={{paddingTop:3, }}>{title}</div></DecodeBoxTopBar>
    <div style={{display:"flex", margin:10}}>
     <form background="#ff0" style={{padding:10}}>
      <p><small>Port</small></p>
      <input
        value={port}
        type='number'
        min="0"
        max="255"
        onChange={e=>{
            try {
                setPort(Number.parseInt(e.target.value))
            } catch (err) {setPort(1)}; }}
      />
      </form>
      <form style={{padding:10}}>
      <p><small>Payload</small></p>
      <input
        value={data}
        type='text'
        maxlength="300"
        onChange={e=>setData(e.target.value)}
      />
      </form>
      <form style={{padding:10}}>
      <p><small>Format</small></p>
      <select onChange={(e)=>setMode(e.target.value)}>
          <option value="auto">Auto</option>
          <option value="hex">Hex</option>
          <option value="base64">Base64</option>
      </select>
      </form>
      <form style={{padding:10}}>
      <p><small>Direction</small></p>
      <select onChange={(e)=>setDirection(e.target.value)}>
          <option value="up">Uplink</option>
          <option value="down">Downlink</option>
      </select>
      </form>
      </div>
        {data.length > 0 && decoded && (
            <div style={{margin:20}}>
                <div style={{display:"flex", cursor:"pointer"}}>
                <small>Output (based on {usemode} format payload)</small>
                <div onClick={() => onCopyPayload()} style={{position:"absolute", right:20, color:"#444"}}> <Icon icon={copyIcon} /></div>
                </div>
                <div style={{background:'#fff', marginTop:4, minHeight:100}}> {JSON.stringify(decoded)} </div>
            </div>)}
        <DecodeBoxBottomBar onClick={()=>{
            if (new Date().getTime()>openTime+1000)
                window.open("https://gitlab.com/sensative/strips-lora-translator-open-source", "_blank")}}>
            <small>
            Visit gitlab.com/sensative/strips-lora-translator-open-source for source code
            </small>
        </DecodeBoxBottomBar>    
    </DecodeBox>
    );
}

export default Decode;
