import _ from 'lodash';
import {set} from 'lodash/fp';

import {profiles} from '../views/settings.view/static-data';
import {version} from '../../package.json';

const actionTypes = {
  ADD_SETTING: 'ADD_SETTING',
  REMOVE_SETTING: 'REMOVE_SETTING',
  ENABLE_REPORT: 'ENABLE_REPORT',
  TOGGLE_REPORT: 'TOGGLE_REPORT',
  USE_PROFILE: 'USE_PROFILE',
  RESET: 'RESET',
};

const actions = {
  addSetting: (setting, newValue, selectedDevice) => ({
    type: actionTypes.ADD_SETTING,
    payload: {setting, newValue, selectedDevice},
  }),
  removeSetting: (setting, selectedDevice) => ({
    type: actionTypes.REMOVE_SETTING,
    payload: {setting, selectedDevice},
  }),
  enableReport: (name, selectedDevice) => ({
    type: actionTypes.ENABLE_REPORT,
    payload: {name, selectedDevice},
  }),
  toggleReport: (reportType, reportName, selectedDevice) => ({
    type: actionTypes.TOGGLE_REPORT,
    payload: {reportType, reportName, selectedDevice},
  }),
  useProfile: (profile, selectedDevice) => ({
    type: actionTypes.USE_PROFILE,
    payload: {profile, selectedDevice},
  }),
  reset: selectedDevice => ({
    type: actionTypes.RESET,
    payload: {selectedDevice},
  }),
};


const defaultState = {
  /* Removed, Morgan review 2021-02-19
     Added, Morgan review 2021-06-08 */
  multisensor: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  guard: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  drip: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  drip_oil: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  comfort: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  presence: {
    addedSettings: [],
    addedReports: {
      enabledReports: [],
      combinedReportsOne: [],
      combinedReportsTwo: [],
      combinedReportsThree: [],
      confirmedReports: [],
    },
    usedProfile: null,
  },
  version,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SETTING: {
      const {setting, newValue, selectedDevice} = action.payload;
      let addedSettings = _.clone(state[selectedDevice].addedSettings);
      let index = _.findIndex(addedSettings, s => s.setting.id === setting.id);
      console.log("Setting index: " + index);
      if (index === -1) {
        addedSettings.push({setting: setting, value:newValue});
        _.map(state[selectedDevice].addedSettings, (v,i)=>console.log(i + ": setting="+v.setting.name + " value=" + v.value));
      }
      else
        addedSettings[index] = {setting: setting, value:newValue}
      return set([selectedDevice, 'addedSettings'], addedSettings, state);
    }
    case actionTypes.REMOVE_SETTING: {
      const {setting, selectedDevice} = action.payload;
      console.log("Removing setting " + setting.name);
      const addedSettings = [];
      _.map(state[selectedDevice].addedSettings, s => (s.setting.id !== setting.id) && addedSettings.push(s));
      return set([selectedDevice, 'addedSettings'], addedSettings, state);
    }
    case actionTypes.ENABLE_REPORT: {
      const {name, selectedDevice} = action.payload;
      if (!_.includes(state[selectedDevice].addedReports.enabledReports, name)) {
        const newReportsArray = [...state[selectedDevice].addedReports.enabledReports, name];
        return set([selectedDevice, 'addedReports', 'enabledReports'], newReportsArray, state);
      }
      return state;
    }
    case actionTypes.TOGGLE_REPORT: {
      const {reportType, reportName, selectedDevice} = action.payload;
      const newReportsArray = _.includes(state[selectedDevice].addedReports[reportType], reportName)
        ? _.without(state[selectedDevice].addedReports[reportType], reportName)
        : [...state[selectedDevice].addedReports[reportType], reportName];
      return set([selectedDevice, 'addedReports', reportType], newReportsArray, state);
    }
    case actionTypes.USE_PROFILE: {
      const {profile, selectedDevice} = action.payload;
      const newObject = {
        addedSettings: profiles[selectedDevice][profile].settings,
        addedReports: {
          enabledReports: profiles[selectedDevice][profile].reports,
          combinedReportsOne: profiles[selectedDevice][profile].combinedReportsOne ? profiles[selectedDevice][profile].combinedReportsOne : [],
          combinedReportsTwo: [],
          combinedReportsThree: [],
          confirmedReports: profiles[selectedDevice][profile].confirmedReports,
        },
        usedProfile: profile,
      };
      return set([selectedDevice], newObject, state);
    }
    case actionTypes.RESET: {
      const {selectedDevice} = action.payload;
      return set(selectedDevice, defaultState[selectedDevice], state);
    }
    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
