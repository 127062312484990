import styled from 'styled-components';

const SelectSettingWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 13px;
    font-weight: 500;
    margin: 15px 0 7px 3px;
  }
`;

export {
  SelectSettingWrapper,
};
