import _ from 'lodash';
import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import rootReducer from './root-reducer';
import {loadState, saveState} from './local-storage';

const middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger());
}

let persistedState = loadState();

const persistedStateIsOld = persistedState => {
  // Its a bad idea to store the metamodel like this, so this is dummy
  return true;
};

if (persistedStateIsOld(persistedState)) {
  console.log('cleared local storaged saved state because it was of an old version');
  persistedState = undefined;
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(applyMiddleware(...middleware)),
);

store.subscribe(_.throttle(() => {
  const state = store.getState();
  saveState({settings: state.settings});
}, 1000));

export default store;
