import React from 'react';
import _ from 'lodash';
import {Icon} from 'react-icons-kit'
import {ic_warning as warningIcon} from 'react-icons-kit/md/ic_warning'
import {ic_edit as editIcon} from 'react-icons-kit/md/ic_edit'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {STEPS} from '../settings.constants';
import {Tooltip, TooltipBadge} from '../settings.styled';

import {
  GeneratePayloadContainer,
  TableContainer,
  TableHeading,
  CombinedReportsWarning,
  PayloadReportTableCell,
  PayloadSettingTableCell,
  PayloadTableHeadingCell,
  PayloadContainer,
  PayloadHeading,
  PayloadBox,
  PayloadText,
  PayloadBase64Container,
  PayloadBase64Text,
  CopyButton,
  ResetContainer,
  ResetNote,
} from './generate-payload.styled';
import {
  StyledTable,
  StyledSwitch,
  StyledLink,
  GhostedButton,
  InfoBox,
} from '../settings.styled';
import {reports, profiles} from '../static-data';
import {
  INSTRUCTIONS,
  REPORT_TYPES,
} from '../settings.constants';
import {ic_content_copy as copyIcon} from 'react-icons-kit/md/ic_content_copy'
import {ic_file_download as saveIcon} from 'react-icons-kit/md/ic_file_download'
import routes from '../../views.routes';
import { colors } from '../../views.constants';

const PayloadSection = props => {
  const onCopyPayload = () => {
    navigator.clipboard.writeText(props.payload);
    toast.info('Payload copied to clipboard');
  };

  const reduceSettings = (arr) => 
    _.reduce(arr, (prev, s) => _.concat(prev, {name: s.setting.name, id: s.setting.id, value: s.value}), []);

  const generateFileContent = () => {
      let content = 'Strips Payload Generator - Saved Payload, www.sensative.com\n';
      content += 'Version:      ' + process.env.REACT_APP_VERSION + '\n';
      content += 'Timestamp:    ' + new Date().toISOString() + '\n';
      content += 'Format:       ' + (props.base64encodingIsEnabled ? 'base64\n' : 'hex\n');
      content += 'LoRa Payload: ' + props.payload+'\n';
      content += 'Note:         Send payload as confirmed downlink on port 11 to know when received.\n'
      content += 'Product:      ' + props.selectedDevice + '\n';
      content += 'Profile:      ' + props.usedProfile + '\n';
      content += 'Settings:\n';
      _.map(props.addedSettings, item => {
        content += '  '+item.setting.name + ': ' + item.value;
        content += ' ' + item.setting.unit.shortName + '\n';
      });
      content += '\n';
      content += 'Reports  (JSON): ' + JSON.stringify(props.addedReports) + '\n';
      content += 'Settings (JSON): ' + JSON.stringify(reduceSettings(props.addedSettings)) + '\n';
      return content;
  }

  const onDownloadTextFile = () => {
    const element = document.createElement("a");
    const file = new Blob([generateFileContent()], {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = `${props.selectedDevice}-${props.usedProfile}.txt`;
    document.body.appendChild(element);
    element.click();
    toast.info('Payload file generated');
  } 
  return (
    props.payload ? (
      <PayloadContainer>
        <PayloadHeading>Resulting payload</PayloadHeading>
        <PayloadBox>
          <PayloadText title={props.payload}>{props.payload}</PayloadText>
          <CopyButton onClick={() => onCopyPayload()}>
            <Icon icon={copyIcon} /></CopyButton>
          <CopyButton onClick={() => onDownloadTextFile()}>
                <Icon icon={saveIcon} /></CopyButton>
        </PayloadBox>
        <PayloadBase64Container>
          <PayloadBase64Text>Base 64 encoded</PayloadBase64Text>
          <StyledSwitch checked={props.base64encodingIsEnabled} onChange={props.toggleBase64Encoding} />
        </PayloadBase64Container>
      </PayloadContainer>
    ) : (
      <InfoBox>No settings or reports added.
        <StyledLink to={routes.PROFILES} activeClassName="selected" onClick={()=>props.setSelectedStep(STEPS.PROFILES)}>Select a Profile</StyledLink>
      </InfoBox>
    )
  );
};

const InstructionsSection = props => (
  props.payload &&
    <InfoBox>
      <h3>Instructions</h3>
      <p>1. {INSTRUCTIONS[0]}</p>
      <p>2. {INSTRUCTIONS[1]}</p>
    </InfoBox>
);

export const SettingsTableSection = props => {
  const handleSettingLinkClick = setting => {
    props.selectCategory(setting.category.name);
    props.selectSetting(setting, props.addedSettings);
  };
  const nonHiddenAddedSettings = _.pickBy(props.addedSettings, (item, index) => {
    return !item.setting.hidden;
  });
  const usedProfile=props.usedProfile;
  const selectedDevice=props.selectedDevice;

  // Create a map of all reports with related settings
  let uniqueReports = [];
  let deviceReports = reports[selectedDevice];
  const addUniqueReportsOnce = enabledList => _.map(enabledList, reportKey => {
      if (undefined === _.find(uniqueReports, value => value === reportKey)) {
        uniqueReports.push(reportKey);
      }
  });
  addUniqueReportsOnce(props.addedReports.enabledReports);
  addUniqueReportsOnce(props.addedReports.combinedReportsOne);
  addUniqueReportsOnce(props.addedReports.combinedReportsTwo);
  addUniqueReportsOnce(props.addedReports.combinedReportsThree);
  
  let allSettings = [];
  let mapSettingsToReports = new Map();
  _.map(nonHiddenAddedSettings, item => allSettings.push(item));
  props.showAdvanced && _.forEach(uniqueReports, (reportKey) => {
    const report = deviceReports[reportKey];
    _.map(report.relatedSettings, setting => {
      if (!setting)
        console.log("*** Bad related setting for report " + reportKey);
      else {
        if (!_.find(allSettings, x => x.setting.id === setting.id))
          allSettings.push({setting:setting, value:setting.default});
        // Additionally, add to the reverse lookup map
        if (mapSettingsToReports.has(setting.id))
          mapSettingsToReports.get(setting.id).push(report);
        else
          mapSettingsToReports.set(setting.id, [report]);
      }
    });
  });

  return (
    !!_.size(allSettings) &&
      <TableContainer>
        <TableHeading>
          <h3>Selected Settings</h3>
        </TableHeading>
        <StyledTable gridTemplateColumns={'300px 1fr 1fr 1fr'}>
          <PayloadTableHeadingCell>Name</PayloadTableHeadingCell>
          <PayloadTableHeadingCell>Value</PayloadTableHeadingCell>
          <PayloadTableHeadingCell>{props.showAdvanced ? "Relates to" : ""}</PayloadTableHeadingCell>
          <PayloadTableHeadingCell></PayloadTableHeadingCell>
            {_.map(allSettings, (item, index) => {
              const profile = usedProfile ? profiles[selectedDevice][usedProfile] : null;
              const shortUnit = item.setting.unit.shortName;
              const ADDED    = 0;
              const SAME     = 1;
              const MODIFIED = 2;
              const DEFAULT  = 3;
              // Note: REMOVED cannot be found by iterating over what is set in settings.
              let profileValue = 0;
              const profileStatus = _.reduce(profile.settings, (prev, profileSetting) => {
                if (profileSetting.setting.id === item.setting.id) {
                  profileValue = profileSetting.value;
                  return (profileValue === item.value) ? SAME : MODIFIED;
                }
                return prev;
              }, item.value === item.setting.default ? DEFAULT : ADDED);

              return <React.Fragment key={index}>
                <PayloadSettingTableCell style={{'color': profileStatus === DEFAULT ? "grey" : "black"}}>
                    {item.setting.name}
                    <Tooltip title={item.setting.description} placement="right">
                      <TooltipBadge>?</TooltipBadge>
                    </Tooltip>
                </PayloadSettingTableCell>
                <PayloadSettingTableCell style={{'color': profileStatus === DEFAULT ? "grey" : "black"}}>
                 {props.showEdit &&
                    <StyledLink to={routes.SETTINGS} onClick={() => handleSettingLinkClick(item.setting)} > <Icon icon={editIcon}/> </StyledLink> }
                  {` ${item.value} ${shortUnit} `}
                </PayloadSettingTableCell>
                <PayloadSettingTableCell style={{'color': profileStatus === DEFAULT ? "grey" : "black"}}>
                    {_.reduce(mapSettingsToReports.get(item.setting.id), (prev, r, ix) => prev + (ix === 0 ? "" : ", ") + r.name, "")}
                </PayloadSettingTableCell>
                <PayloadSettingTableCell style={{'color': profileStatus === DEFAULT ? "grey" : "black"}}>{ profile &&
                  (profileStatus === ADDED ? 'Added vs Profile' :
                  (profileStatus === MODIFIED ? `Modified from ${profileValue} ${shortUnit}` : 
                  (profileStatus === DEFAULT ? 'Default' : 'As in Profile')))}
                </PayloadSettingTableCell>
              </React.Fragment>
            })}
        </StyledTable>
      </TableContainer>
  );
};

const ReportsTableSectionPart = props => {return props.reports.length>0 &&
    <TableContainer>
      <TableHeading>
        <h3>{props.tableName}</h3>
        { props.showWarning &&
        <CombinedReportsWarning>
          <Icon icon={warningIcon} />
          <p>None of the following reports are enabled - the report group will never be sent</p>
        </CombinedReportsWarning>
        }
      </TableHeading>
      <StyledTable gridTemplateColumns={'300px 1fr 100px'}>
        <PayloadTableHeadingCell>Name</PayloadTableHeadingCell>
        <PayloadTableHeadingCell />
        <PayloadTableHeadingCell />
          {_.map(props.reports, report =>
            reports[props.selectedDevice][report] && ((!props.reportFilter) || _.includes(props.reportFilter, report)) ? 
            <React.Fragment key={report}>
              <PayloadReportTableCell>
                { reports[props.selectedDevice][report].name }
                <Tooltip title={reports[props.selectedDevice][report].description} placement="right">
                  <TooltipBadge>?</TooltipBadge>
                </Tooltip>
              </PayloadReportTableCell>
              <PayloadReportTableCell>{reports[props.selectedDevice][report].short}</PayloadReportTableCell>
              <PayloadReportTableCell>
                {props.usedProfile &&
                (_.includes(profiles[props.selectedDevice][props.usedProfile].reports, report) ||
                 _.includes(profiles[props.selectedDevice][props.usedProfile].combinedReportsOne, report)) ? '' : 'Added'}
              </PayloadReportTableCell>
            </React.Fragment> : null
          )}
      </StyledTable>
    </TableContainer>}

export const ReportsTableSection = props => props.showAdvanced || _.size(props.addedReports) > 0 ? (
    _.map(REPORT_TYPES, reportType => {
      let reportFilter = null;
      if (reportType.name==="confirmedReports")
        reportFilter = props.addedReports.enabledReports;
      return <ReportsTableSectionPart
          setSelectedStep={props.setSelectedStep}
          key={reportType.name}
          tableName={reportType.prettyName}
          selectedDevice={props.selectedDevice}
          reports={props.addedReports[reportType.name] ? props.addedReports[reportType.name] : []}
          reportFilter={reportFilter}
          toggleReport={props.toggleReport}
          reportType={reportType.name}
          showWarning={_.includes(props.combinedReportsWarnings, reportType.name)}
          usedProfile={props.usedProfile}
          showAdvanced={props.showAdvanced}
        />
    }))
    : <InfoBox>No reports enabled.</InfoBox>

const ResetSection = props => (
  props.payload &&
    <ResetContainer>
      <GhostedButton
        background={colors.RED}
        onClick={() => {if (window.confirm('Are you sure you want to reset?')) props.reset(props.selectedDevice);}}
      >
        Reset
      </GhostedButton>
      <ResetNote>Removes all settings and reports</ResetNote>
    </ResetContainer>
);

const GeneratePayload = props => (
  <GeneratePayloadContainer>
    <PayloadSection {...props} />
    <InstructionsSection {...props} />
    <SettingsTableSection {...props} />
    <ReportsTableSection {...props} />
    <ResetSection {...props} />
  </GeneratePayloadContainer>
);

export default GeneratePayload;
