import styled from 'styled-components';

import {colors} from '../../views.constants';
import checkImg from '../../../assets/images/check.png';
import {
  TableCell,
  TableHeadingCell,
} from '../settings.styled';

const EnableReportsContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 0 0 0 0;
`;

const StyledCheckbox = styled.div`
  width: ${props => props.size || 16}px;
  height: ${props => props.size || 16}px;
  border: 2px solid;
  border-radius: 2px;
  background-size: ${props => props.size || 16}px;
  background-image: ${props => props.checked ? `url(${checkImg})` : "none"};
  background-color: ${props => ((props.disabled && props.checked)||props.warn) ? colors.WARNING : props.checked ? props.color : "none"};
  border-color: ${props => props.checked && !props.disabled && !props.warn ? props.color : colors.GREY_25};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;

const ReportsTableCell = styled(TableCell)`
  padding: 24px 0 30px 0;
  &:nth-child(6n+1) {
    border-left-width: 1px;
    padding-left: 16px;
  }
  &:nth-child(6n) {
    border-right-width: 1px;
    padding-right: 12px;
    justify-content: flex-end;
  }
`;

const ColumnReportsTableCell = styled(ReportsTableCell)`
  flex-direction: column;
  align-items: flex-start;
`;

const ReportsTableHeadingCell = styled(TableHeadingCell)`
  &:nth-child(6n+1) {
    padding-left: 12px;
    border-left-width: 1px;
    border-radius: 4px 0 0 0;
  }
  &:nth-child(6n) {
    border-right-width: 1px;
    border-radius: 0 4px 0 0;
    justify-content: flex-start;
  }
`;

const TableReportName = styled.div`
  line-height: normal;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
`;

const TableReportDescription = styled.div`
  line-height: normal;
  padding-right: 40px;
  line-height: 1.4;
  color: ${colors.GREY_28};
`;

const TableSetting = styled.div`
  min-height: 20px;
  color: ${props => props.isAdded ? colors.GREY_4 : colors.GREY_28};
  font-weight: ${props => props.isAdded ? '600' : 'normal'};
`;

export {
  EnableReportsContainer,
  ReportsTableCell,
  ColumnReportsTableCell,
  ReportsTableHeadingCell,
  TableReportName,
  TableReportDescription,
  TableSetting,
  StyledCheckbox,
};
