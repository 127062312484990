import React from 'react';

import {SENSATIVE_ICON} from '../views.constants';

import {Bar, BarWrapper} from './navbar.styled';

const NavBar = props => (
  <Bar>
    <BarWrapper>
      <img src={SENSATIVE_ICON} alt="Sensative" width="23px" height="25px" />
      <h1>Strips LoRa Config Tool</h1>
    </BarWrapper>
  </Bar>
);

export default NavBar;
