import _ from 'lodash';
import {createSelector} from 'reselect';

import {
  reports,
  profiles,
} from './static-data';
import {
  PAYLOAD_PREFIX,
  REPORT_TYPES,
} from './settings.constants';

const intToHex1 = number => {
  return ('00' + number.toString(16).toUpperCase()).slice(-2);
}

const intToHex4 = number => {
  if (number < 0) {
    number = number >>> 0;
  }
  return ('00000000' + number.toString(16).toUpperCase()).slice(-8);
}

const generateReportsPayload = (allAddedReports, reportType, selectedDevice, addMask = 0) => {
  const addedReports = allAddedReports[reportType.name];
  if (_.isEmpty(addedReports)) return '';
  const enableReportsConfig = _.reduce(addedReports, (result, report) => {
    if (reports[selectedDevice] && reports[selectedDevice][report]) {
      result += reports[selectedDevice][report].value;
    } else {
      console.log("Report " + report + " not applicable to " + selectedDevice);
    }
    return result;
  
  }, 0);
  return reportType.id + intToHex4(enableReportsConfig|addMask);
};

const generateReportsPayloadAddDefaultConfirm = (allAddedReports, reportType, selectedDevice) => {
  const data = generateReportsPayload(allAddedReports, reportType, selectedDevice, 0x1);
  console.log("Adding default report mask", data);
  return data;
}

const generateSettingsPayload = (addedSettings, selectedDevice) => {
  if (addedSettings.length === 0) return '';
  return _.reduce(addedSettings, (result, item, index) => {
    const coefficient = item.setting.coefficient;
    const value = item.value * (coefficient || 1);
    result += intToHex1(item.setting.id) + intToHex4(parseInt(value));
    return result;
  }, '');
};

const selectAddedSettingsForSelectedDevice = createSelector(
  state => state.settings,
  state => state.views.settings.selectedDevice,
  (settingsState, selectedDevice) => {
    if (!selectedDevice) return [];
    return settingsState[selectedDevice].addedSettings;
  }
);

const selectAddedReportsForSelectedDevice = createSelector(
  state => state.settings,
  state => state.views.settings.selectedDevice,
  (settingsState, selectedDevice) => {
    if (!selectedDevice) return {};
    return settingsState[selectedDevice].addedReports;
  }
);

const selectPayload = createSelector(
  selectAddedSettingsForSelectedDevice,
  selectAddedReportsForSelectedDevice,
  state => state.views.settings.base64encodingIsEnabled,
  state => state.views.settings.selectedDevice,
  (addedSettings, addedReports, base64encodingIsEnabled, selectedDevice) => {
    if ((_.every(addedReports, _.isEmpty) && _.isEmpty(addedSettings)) || !selectedDevice) {
      return '';
    }
    const enabledReportsPayload = generateReportsPayload(addedReports, REPORT_TYPES.enabledReports, selectedDevice);
    const combinedReportsOnePayload = generateReportsPayload(addedReports, REPORT_TYPES.combinedReportsOne, selectedDevice);
    const combinedReportsTwoPayload = generateReportsPayload(addedReports, REPORT_TYPES.combinedReportsTwo, selectedDevice);
    const combinedReportsThreePayload = generateReportsPayload(addedReports, REPORT_TYPES.combinedReportsThree, selectedDevice);
    const confirmedReportsPayload = generateReportsPayloadAddDefaultConfirm(addedReports, REPORT_TYPES.confirmedReports, selectedDevice);
    const settingsPayload = generateSettingsPayload(addedSettings, selectedDevice);
    const totalPayload = PAYLOAD_PREFIX
    + enabledReportsPayload
    + combinedReportsOnePayload
    + combinedReportsTwoPayload
    + combinedReportsThreePayload
    + settingsPayload
    + confirmedReportsPayload;
    if (base64encodingIsEnabled) {
      return Buffer.from(totalPayload, 'hex').toString('base64');
    }
    return totalPayload;
  }
);

const selectValidationError = createSelector(
  state => state.views.settings.newValue,
  state => state.views.settings.selectedSetting,
  state => state.views.settings.selectedDevice,
  (newValue, selectedSetting, selectedDevice) => {
    if (newValue === '') return '';
    const {min, max, coefficient, allowZero, exemptedValue} = selectedSetting;
    
    if (isNaN(newValue)) {
      return 'Not a valid number';
    }
    if (!coefficient && newValue % 1 !== 0) {
      return 'Decimal value not allowed';
    }
    if (coefficient && newValue % 1 !== 0 && parseFloat(newValue).toString().length > 3) {
      return 'Cannot have more than 1 decimal'
    }
    if (allowZero && newValue === '0') {
      return '';
    }
    //if (newValue == exemptedValue) return '';
    if (newValue > max) {
      let message = `Cannot be greater than ${max}`;
      if (allowZero && max < 0) message += ' (except for 0)';
      return message;
    }
    if (newValue < min) {
      let message = `Cannot be less than ${min}`;
      if (allowZero && min > 0) message += ' (except for 0)';
      return message;
    }
    return '';
  }
);

const selectSelectedSetting = createSelector(
  state => _.get(state, 'views.settings.selectedSetting'),
  state => state.views.settings.selectedDevice,
  (selectedSetting, selectedDevice) => {
    if (!selectedSetting) return null;
    const relatedReports =_.reduce(reports[selectedDevice], (result, report, key) => {
      if (_.includes(report.relatedSettings, selectedSetting)) {
        result[key] = report.name;
      }
      return result;
    }, {});
    let allowedValuesText = `${selectedSetting.min}-${selectedSetting.max}`;
    if (selectedSetting.allowZero && (selectedSetting.min > 0 || selectedSetting.max < 0)) {
      allowedValuesText += ' and 0';
    }
    return {
      ...selectedSetting,
      id: selectedSetting.id,
      relatedReports,
      allowedValuesText,
    };
  }
);

const selectNumAddedReports = createSelector(
  selectAddedReportsForSelectedDevice,
  addedReports => {
    return _.sum([_.size(addedReports.enabledReports),
    _.size(addedReports.combinedReportsOne),
    _.size(addedReports.combinedReportsTwo),
    _.size(addedReports.combinedReportsThree)]);
  }
);

const selectCombinedReportsWarnings = createSelector(
  state => state.settings.addedReports,
  addedReports => {
    const combinedReportKeys = _.without(_.keys(addedReports), 'enabledReports');
    return _.reduce(combinedReportKeys, (result, key) => {
      if (_.isEmpty(_.intersection(addedReports.enabledReports, addedReports[key]))) {
        result.push(key);
      }
      return result;
    }, []);
  }
);

const selectUsedProfile = createSelector(
  state => state.views.settings.selectedDevice,
  state => state.settings,
  (selectedDevice, settingsState) => {
    if (!selectedDevice) return null;
    return settingsState[selectedDevice].usedProfile;
  }
);

const selectProfilesSelectOptions = createSelector(
  state => state.views.settings.selectedDevice,
  selectUsedProfile,
  (selectedDevice, usedProfile) => {
    if (!selectedDevice) return [];
    const options = _.map(profiles[selectedDevice], ({name}, key) => {
      let label = name;
      if (usedProfile === key) {
        label = '** ' + label + ' (Current)';
      }
      return {
        value: key,
        label,
      }
    });
    return options;
  }
);

const selectSelectedProfileData = createSelector(
  state => state.views.settings.selectedDevice,
  state => state.views.settings.selectedProfile,
  (selectedDevice, selectedProfile) => {
    if (!selectedDevice || !selectedProfile) return null;
    return {
      ...profiles[selectedDevice][selectedProfile],
      key: selectedProfile,
    };
  }
);

const selectSelectedProfileOption = createSelector(
  state => state.views.settings.selectedDevice,
  selectSelectedProfileData,
  (selectedDevice, selectedProfileData) => {
    if (!selectedProfileData) return null;
    return {
      value: selectedDevice,
      label: selectedProfileData.name
    };
  }
);

export {
  selectPayload,
  selectValidationError,
  selectSelectedSetting,
  selectAddedSettingsForSelectedDevice,
  selectAddedReportsForSelectedDevice,
  selectNumAddedReports,
  selectCombinedReportsWarnings,
  selectProfilesSelectOptions,
  selectSelectedProfileData,
  selectSelectedProfileOption,
  selectUsedProfile,
};
