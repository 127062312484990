import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import {
  Wrapper,
  SelectHeading,
  ButtonsContainer,
  DescriptionContainer,
  SettingsTableHeadingCell,
  SettingsTableCell,
  TableContainer,
  NoProfilesNote,
} from './profiles.styled';
import {
  StyledTable,
  InfoBox,
  Button,
} from '../settings.styled';
import {
  profiles,
  reports,
} from '../static-data'
import {STEPS} from '../settings.constants'

const Profiles = props => {

  const setNewProfile = () => {
    props.useProfile(props.selectedProfileData.key, props.selectedDevice);
    props.setAdvancedMode(props.selectedProfileData.hasOwnProperty('advanced') ? props.selectedProfileData.advanced : false);
    props.setSelectedStep(STEPS.SECOND);
  }

  const onUseProfileButtonClick = () => {
    const warningMessage = 'Warning: All previousely added settings and reports will be cleared. Are you sure you want to continue?';
    const showWarning = _.size(props.addedSettings.length) + props.numAddedReports > 0;
    if (showWarning) {
      if (window.confirm(warningMessage)) {
        setNewProfile();
      }
    } else {
      setNewProfile();
    }
  };

  const SelectProfile = () => (
    <>
      {!props.usedProfile && <InfoBox>Profiles are lab verified configurations for the selected device model.</InfoBox>}
      <SelectHeading>Available profiles</SelectHeading>
      <Select
        options={props.profileOptions}
        onChange={evt => props.selectProfile(evt.value)}
        isSearchable={false}
        value={props.selectedProfileOption}
      />
    </>
  );

  const SettingsTable = () => (
    <TableContainer>
      <h4>Profile Settings</h4>
      <StyledTable gridTemplateColumns={'300px 1fr'}>
        <SettingsTableHeadingCell>Name</SettingsTableHeadingCell>
        <SettingsTableHeadingCell>Value</SettingsTableHeadingCell>
        { profiles[props.selectedDevice][props.selectedProfileData.key].settings.length > 0 ?
          _.map(profiles[props.selectedDevice][props.selectedProfileData.key].settings, (item) => {
            return item.setting.hidden || <React.Fragment key={item.setting.id}>
            <SettingsTableCell>{item.setting.name}</SettingsTableCell>
            <SettingsTableCell>{`${item.value} ${item.setting.unit.shortName}`}</SettingsTableCell>
          </React.Fragment>
        }) : <SettingsTableCell>No settings required.</SettingsTableCell>}
      </StyledTable>
    </TableContainer>
);

  const ReportsTable = () => (
    <TableContainer >
      <h4>Profile Reports</h4>
      <StyledTable gridTemplateColumns={'300px 1fr'}>
        <SettingsTableHeadingCell>Name</SettingsTableHeadingCell>
        <SettingsTableHeadingCell>Function</SettingsTableHeadingCell>
        {      profiles[props.selectedDevice][props.selectedProfileData.key].reports.length > 0 ?
         _.map(profiles[props.selectedDevice][props.selectedProfileData.key].reports, report =>
          <React.Fragment key={report}>
            <SettingsTableCell>{reports[props.selectedDevice][report].name}</SettingsTableCell>
            <SettingsTableCell>{reports[props.selectedDevice][report].short}</SettingsTableCell>
          </React.Fragment>
        ) : <SettingsTableCell>No reports specified.</SettingsTableCell>}
        {_.map(profiles[props.selectedDevice][props.selectedProfileData.key].combinedReportsOne, report =>
          <React.Fragment key={report}>
            <SettingsTableCell> (Grouped) {reports[props.selectedDevice][report].name}</SettingsTableCell>
            <SettingsTableCell>{reports[props.selectedDevice][report].description}</SettingsTableCell>
          </React.Fragment>
          )}
      </StyledTable>
    </TableContainer>
  );

  const Buttons = () => (
    <ButtonsContainer>
      {props.usedProfile !== props.selectedProfileData.key ?
        <Button onClick={() => onUseProfileButtonClick(props)}>Select this Profile</Button> :
        <React.Fragment>
        <Button onClick={() => onUseProfileButtonClick(props)}>Reset to this Profile</Button>
        <Button onClick={() => props.setSelectedStep(STEPS.REPORTS)}>Proceed</Button>
        </React.Fragment>
      }
    </ButtonsContainer>
  );

  return (
    <Wrapper>
      {props.profileOptions.length === 0 &&
        <NoProfilesNote>No profiles available for this device</NoProfilesNote>
      }
      {props.profileOptions.length > 0 &&
        <SelectProfile />
      }
      {props.selectedProfileData &&
        <>
          <DescriptionContainer>
            <h4>Description</h4>
            <p>{props.selectedProfileData.description}</p>
          </DescriptionContainer>
          { (!profiles[props.selectedDevice][props.selectedProfileData.key].skipProfileSummary) && 
            <React.Fragment>
              <ReportsTable />
              <SettingsTable />
            </React.Fragment>
          }
          <Buttons />
        </>
      }
    </Wrapper>
  );

};

export default Profiles;
