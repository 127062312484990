import React from 'react';
import _ from 'lodash';

import {
  MenuBarContainer,
  AddedCounter,
  StyledLink,
} from './menu-bar.styled';
import routes from '../../views.routes';
import {STEPS} from '../settings.constants'

const MenuBar = props => (
  <MenuBarContainer>
    <StyledLink to={routes.PROFILES} activeClassName="selected" onClick={()=>props.setSelectedStep(STEPS.PROFILES)}>
      Step 1: Profile
      {props.usedProfile && <AddedCounter isZero={!props.usedProfile}>
        {props.usedProfile ? 1 : 0}
      </AddedCounter> }
    </StyledLink>
    {props.selectedDevice && props.usedProfile &&
    <StyledLink to={routes.REPORTS} activeClassName="selected"  onClick={()=>props.setSelectedStep(STEPS.REPORTS)}>
      Step 2: Reports
      {props.numAddedReports>0 &&
      <AddedCounter>
        {props.numAddedReports}
      </AddedCounter>}
    </StyledLink> }
    {props.selectedDevice && props.usedProfile &&
    <StyledLink to={routes.SETTINGS} activeClassName="selected"  onClick={()=>props.setSelectedStep(STEPS.SETTINGS)}>
      Step 3: Settings
      {(0 !== _.size(props.addedSettings)) && <AddedCounter>
        {_.size(props.addedSettings)}
      </AddedCounter> }
    </StyledLink> }
    {props.selectedDevice && props.usedProfile &&
    <StyledLink to={routes.PAYLOAD} activeClassName="selected"  onClick={()=>props.setSelectedStep(STEPS.PAYLOAD)}>
      Step 4: Payload
    </StyledLink> }
  </MenuBarContainer>
);

export default MenuBar;
