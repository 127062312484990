import SENSATIVE_ICON from '../assets/images/yggio/sensative-icon.svg';


const colors = {
  // Greys
  TRUE_WHITE: '#FFF',
  WHITE: '#FBFBFB',
  GREY_0: '#f8f8f8',
  GREY_02: '#F4F4F4',
  GREY_03: '#EEE',
  GREY_05: '#EBEBEB',
  GREY_1: '#E5E5E5',
  GREY_15: '#DDD',
  GREY_2: '#CCC',
  GREY_25: '#999',
  GREY_27: '#777',
  GREY_28: '#595959',
  GREY_3: '#444',
  GREY_4: '#333',
  BLACK: '#111',
  // Greens
  RACING_GREEN: '#004225',
  YGGIO_GREEN: '#01676C',
  LIGHT_GREEN: '#3B7D45',
  MEDIUM_GREEN: '#49AE68',
  DARK_GREEN: '#004C19',
  // Other
  BLUE: '#2289fc', // input outline
  RED: '#bd3e3e',
  RED_BRIGHT: '#e33232',
  WARNING: '#ED853B',
  PURPLE: '#ba68c8',
  TEAL: '#4db6ac',
};

export {
  colors,
  SENSATIVE_ICON,
};
