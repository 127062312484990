import styled from 'styled-components';

import {colors} from '../../../views.constants';

const SettingContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding-top: 10px;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-auto-rows: min-content;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
  }
`;

const SettingHeader = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GREY_2};
  height: 42px;
  h3 {
    font-size: 15px;
    margin-left: 7px;
    font-weight: 600;
  }
`;

const SettingDescriptionHeading = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  padding: 25px 0 5px 0;
`;

const SettingDescription = styled.div`
  grid-column: 1 / -1;
  grid-row: 3;
  padding-bottom: 25px;
`;

const SettingUnitHeader = styled.div`
  grid-column: 1;
  grid-row: 4;
  padding: 0 40px 5px 0;
`;

const SettingDefaultValueHeader = styled.div`
  grid-column: 2;
  grid-row: 4;
  padding-right: 40px;
`;

const SettingAllowedValuesHeader = styled.div`
  grid-column: 3;
  grid-row: 4;
  padding-right: 40px;
`;

const SettingRelatedReportsHeader = styled.div`
  grid-column: 4;
  grid-row: 4;
  display: flex;
`;

const SettingUnit = styled.div`
  grid-column: 1;
  grid-row: 5;
  padding-right: 40px;
`;

const SettingDefaultValue = styled.div`
  grid-column: 2;
  grid-row: 5;
  padding-right: 40px;
`;

const SettingAllowedValues = styled.div`
  grid-column: 3;
  grid-row: 5;
  padding-right: 40px;
`;

const SettingRelatedReports = styled.div`
  grid-column: 4;
  grid-row: 5;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 3px;
  }
`;

const SettingNewValueHeading = styled.div`
  grid-column: 1 / -1;
  grid-row: 6;
  padding: 20px 0 7px 0;
`;

const SettingNewValue = styled.div`
  grid-column: 1 / -1;
  grid-row: 7;
  border-bottom: 1px solid ${colors.GREY_2};
  padding-bottom: 23px;
  p {
    color: red;
    font-size: 13px;
    margin: 4px 0 0 2px;
  }
`;

const SettingButton = styled.div`
  grid-column: 1 / -1;
  grid-row: 8;
  padding-top: 20px;
`;

const AddedSettingItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: ${colors.MEDIUM_GREEN};
    font-weight: 600;
  }
`;

const AddedSettingIconContainer = styled.div`
  display: flex;
  color: ${colors.MEDIUM_GREEN};
  p {
    margin-left: 6px;
  }
`;

export {
  SettingContainer,
  SettingHeader,
  SettingDescriptionHeading,
  SettingDescription,
  SettingUnitHeader,
  SettingDefaultValueHeader,
  SettingAllowedValuesHeader,
  SettingRelatedReportsHeader,
  SettingUnit,
  SettingDefaultValue,
  SettingAllowedValues,
  SettingNewValueHeading,
  SettingRelatedReports,
  SettingNewValue,
  SettingButton,
  AddedSettingItems,
  AddedSettingIconContainer,
};
