// Copyright (C) 2021, Sensative AB (www.sensative.com)

import { SETTINGS } from './settings';

const REPORTS = {
  emptyReport: {
    name: 'Linkcheck',
    short: 'Linkcheck',
    description: 'Reports idd data.',
    value: 1,
  },
  batteryReport: {
    name: 'Battery Report',
    short: 'Current battery status (%)',
    description: 'Reports battery status, limited to be combined only with other reports.',
    value: 2,
    relatedSettings: [],
    onlyCombined: true, // Do not allow for this to be set due to bug in battery alarm
  },
  tempReport: {
    name: 'Temperature Report',
    short: 'Current temperature',
    description: 'Reports temperature when the value has changed according to the value set in “Temp Send Immediately Threshold”',
    value: 4,
    relatedSettings: [
      SETTINGS.tempPollInterval, // 0x04
      SETTINGS.tempSendImmediatelyThreshold, // 0x05
      SETTINGS.tempSendThrottledThreshold, // 0x06
      SETTINGS.tempSendThrottledTime, // 0x07
    ],
  },
  tempAlarm: {
    name: 'Temperature Alarm',
    short: 'Current temperature',
    description: 'Triggered when the temperature reaches values set in “Temperature High/Low Alarm”',
    value: 8,
    relatedSettings: [
      SETTINGS.tempPollInterval,    // 0x04
      SETTINGS.tempLowAlarmLevel,   // '0x08'
      SETTINGS.tempHighAlarmLevel,  // '0x09' 
      SETTINGS.tempAlarmHysteresis, // '0x0a'
    ],
  },
  averageTempReport: {
    name: 'Average Temperature Report',
    short: 'Average temperature',
    description: 'A report of average temperature measured over time set in “Average Temperature Time”. Note: This report must be enabled if part of a RG.',
    value: 16,
    relatedSettings: [
      SETTINGS.averageTempTime,
      SETTINGS.averageTempSendImmediatelyThreshold, 
      SETTINGS.tempPollInterval,    // 0x04
    ],
    stateful: true, // Cannot follow in a combined report
  },
  averageTempAlarm: {
    name: 'Average Temperature Alarm',
    short: 'Average temperature',
    description: 'A report when average temperature goes above/below “Average Temperature High/Low” setting. Note: This report must be enabled if part of a RG.',
    value: 32,
    relatedSettings: [
      SETTINGS.averageTempAlarmLowLevel, 
      SETTINGS.averageTempAlarmHighLevel, 
      SETTINGS.tempPollInterval,
      SETTINGS.averageTempTime,
      SETTINGS.averageTempAlarmHysteresis, 
    ],
    stateful: true, // Cannot follow in a combined report
  },
  humidityReport: {
    name: 'Humidity Report',
    short: 'Humidity condition changes',
    description: 'A humidity report when value of relative humidity changes according to the value set in "Humidity Threshold”',
    value: 64,
    relatedSettings: [
      SETTINGS.humPollInterval,
      SETTINGS.humThresholdLevel,
    ],
  },
  ambientLightReport: {
    name: 'Ambient Light Report',
    short: 'Light condition changes',
    description: 'An ambient light report when value changes according to “Ambient Light High/Low Level“ (be sure to align this report with the “Ambient Light Polling Interval)',
    value: 128,
    relatedSettings: [
      SETTINGS.lightPollInterval,
      SETTINGS.lightHighLevel1,
      SETTINGS.lightLowLevel1,
    ],
  },
  ambientLightReport2: {
    name: 'Ambient Light Report 2',
    short: 'Light condition changes',
    description: 'An ambient light report when value changes according to “Ambient Light High/Low Level 2“ (be sure to align this report with the “Ambient Light Polling Interval)',
    value: 256,
    relatedSettings: [
      SETTINGS.lightPollInterval,
      SETTINGS.lightHighLevel2,
      SETTINGS.lightLowLevel2,
    ],
  },
  doorReport: {
    name: 'Door Report',
    short: 'Door closed or opened',
    description: 'A report of the door when status has changed',
    value: 512,
    relatedSettings: [
      SETTINGS.basePollInterval,
    ],
  },
  doorAlarm: {
    name: 'Door Alarm',
    short: 'Door closed or opened',
    description: 'A report to alert when status of door has changed',
    value: 1024,
    relatedSettings: [
      SETTINGS.doorAlarmInvert,
      SETTINGS.basePollInterval,
    ],
  },
  floodReport: {
    name: 'Flood Report',
    short: 'Liquid detection',
    description: 'Reports the value of the flood sensor at a set interval: 0 = completely dry, 100 = fully soaked',
    value: 8192,
    relatedSettings: [
      SETTINGS.floodPollInterval,
      SETTINGS.floodReportInterval,
    ],
  },
  floodAlarm: {
    name: 'Flood Alarm',
    short: 'Liquid detection',
    description: 'Triggered when flood value exceeds "Flood Alarm Threshold"',
    value: 16384,
    relatedSettings: [
      SETTINGS.floodPollInterval,
      SETTINGS.floodAlarmThreshold,
      SETTINGS.floodAlarmHysteresis,
    ],
  },
  tempHumReport: {
    name: 'Temperature Humidity Report',
    short: 'Temperature and Humidity in one uplink',
    description: 'A compressed report that includes both temperature and humidity values. Requires Temperature and Humidity reports to be enabled.',
    value: 65536,
    relatedSettings: [
      SETTINGS.tempPollInterval,
      SETTINGS.tempSendThrottledTime,
      SETTINGS.tempSendThrottledThreshold,
      SETTINGS.tempSendImmediatelyThreshold,
      SETTINGS.humPollInterval,
      SETTINGS.humThresholdLevel,
    ],
  },
  avgTempHumReport: {
    name: 'Average Temperature Humidity Report',
    short: 'Average Temperature and Humidity in one uplink',
    description: 'A compressed report that include both average temperature and humidity values. Requires Average Temperature and Humidity reports to be enabled. Must be enabled if part of a RG.',
    value: 131072,
    relatedSettings: [
      SETTINGS.tempPollInterval,
      SETTINGS.averageTempTime,
      SETTINGS.averageTempSendThrottledTime,
      SETTINGS.averageTempSendThrottledThreshold,
      SETTINGS.averageTempSendImmediatelyThreshold,
      SETTINGS.humPollInterval,
      SETTINGS.humThresholdLevel,
    ],
    stateful: true, // Cannot follow in a combined report
  },
  tempDoorReport: {
    name: 'Temperature Door Report',
    short: 'Door status and temperature in one uplink',
    description: 'A compressed report including both temperature and door values in one uplink. Requires door and temperature reports to be enabled.',
    value: 262144,
    relatedSettings: [
      SETTINGS.tempPollInterval,
      SETTINGS.tempSendThrottledTime,
      SETTINGS.tempSendThrottledThreshold,
      SETTINGS.tempSendImmediatelyThreshold,
    ],
  },
  userSwitchAlarm: {
    name: '+Switch Alarm',
    short: '+Switch accessory was toggled',
    description: 'The +Switch was toggled (requires +Switch accessory)',
    value: 1 << 22,
    relatedSettings: [
      SETTINGS.basePollInterval
    ],
  },
  doorCountReport: {
    name: 'Door Count Report',
    short: 'How many times a door was opened',
    description: 'This reports how many times a door was opened during the interval',
    value: 1 << 23,
    relatedSettings: [
      SETTINGS.doorCountInterval,
      SETTINGS.basePollInterval
    ],
    stateful: true, // Cannot follow in a combined report
  },
  oilAlarm: {
    name: 'Oil Alarm',
    short: 'Oil Alarm',
    description: 'This alarm is sent if oil sensor detects oil or that other sensor was removed',
    value: 1 << 15,
    relatedSettings: [
      SETTINGS.oilThreshold,
      SETTINGS.oilHysteresis,
      SETTINGS.oilFilterFactor,
      SETTINGS.floodPollInterval,
    ],
    stateful: true, // Cannot follow in a combined report
  },
  tamperReport: {
    name: 'Magnet Tampering Report',
    short: 'Magnetic tampering',
    description: 'This is sent if the sensor detects additional magnets while door/window is closed',
    value: 1 << 11,
    relatedSettings: [
      SETTINGS.basePollInterval,
    ],
  },
  tamperAlarm: {
    name: 'Magnet Tampering Alarm',
    short: 'Magnetic tampering',
    description: 'This is sent if the sensor detects additional magnets while door/window is closed',
    value: 1 << 12,
    relatedSettings: [
      SETTINGS.basePollInterval,
    ],
  },
  presenceReport: {
    name: 'Presence Report',
    short: 'A person is present',
    description: 'Sends true (occupied) when a person is detected. Sends false (not occupied) if multiple movements have not been detected within timeout seconds. Note: Must be enabled to function correctly in a RG.',
    value: 1 << 24,
    relatedSettings: [
      SETTINGS.presencePollInterval,
      SETTINGS.presenceTimeout,
      SETTINGS.presenceThreshold,
    ],
    stateful: true, // Cannot follow in a combined report
  },
  closeProximityAlarm: {
    name: 'Close Proximity Alarm',
    short: 'An object is present',
    description: 'Sends true (present) if an object is in close proximity to, but not pressed against the sensor (1 - 10cm approximate range depending on material). Sends false if the object is removed.',
    value: 1 << 27,
    relatedSettings: [
      SETTINGS.presencePollInterval,
      SETTINGS.presenceThreshold,
      SETTINGS.objectDetectCount,
    ],
  },
  highProximityAlarm: {
    name: 'High Proximity Alarm',
    short: 'An object is present',
    description: 'Sends true (present) if an object is in far proximity to, but not pressed against the sensor (2 - 28cm approximate range depending on material). Sends false if the object is removed.',
    value: 1 << 27,
    relatedSettings: [
      SETTINGS.presencePollInterval,
      SETTINGS.presenceThreshold,
      SETTINGS.objectDetectCount,
      SETTINGS.sensorConfiguration,
    ],
  },
  disinfectAlarm: {
    name: 'Disinfect Alarm',
    short: 'A space is dirty, occupied, under cleaning, or clean',
    description: 'Reports the following states: dirty (0), occupied (1), cleaning (2) or clean (3), as a message. Requires the +Switch accessory or a magnet to square edge to trigger the cleaning and subsequent clean states. Note: Must be enabled to function correctly in a RG. See manual for further instructions.',
    value: 1 << 28,
    relatedSettings: [
      SETTINGS.presencePollInterval,
      SETTINGS.presenceTimeout,
      SETTINGS.presenceThreshold,
      SETTINGS.ledLevel,
    ],
    stateful: true, // Cannot follow in a combined report
  },
  irProximityReport: {
    name: 'IR Proximity Report',
    short: 'Reports the reflection value from the IR sensor',
    description: 'Reports the reflection value of the IR sensor. Useful when troubleshooting or tuning.',
    value: 1 << 25,
    relatedSettings: [
      SETTINGS.presencePollInterval,
      SETTINGS.presenceTimeout,
      SETTINGS.presenceThreshold,
      SETTINGS.ledLevel,
    ],
  },
}

const guard = {
  // Magnet
  doorAlarm : REPORTS.doorAlarm,
  doorReport : REPORTS.doorReport,
  doorCountReport : REPORTS.doorCountReport,
  // Temp (partial)
  tempAlarm : REPORTS.tempAlarm,
  averageTempReport : REPORTS.averageTempReport,
  // Tampering
  tamperAlarm: REPORTS.tamperAlarm,
  tamperReport: REPORTS.tamperReport,
  // Battery
  batteryReport: REPORTS.batteryReport,
};

const guard_lite = {
  // Magnet
  doorAlarm : REPORTS.doorAlarm,
  doorReport : REPORTS.doorReport,
  doorCountReport : REPORTS.doorCountReport,
  // Tampering
  tamperAlarm: REPORTS.tamperAlarm,
  tamperReport: REPORTS.tamperReport,
  // Battery
  batteryReport: REPORTS.batteryReport,
};

const comfort = {
  // Temp (partial)
  averageTempAlarm : REPORTS.averageTempAlarm,
  averageTempReport : REPORTS.averageTempReport,
  tempAlarm : REPORTS.tempAlarm,
  tempReport : REPORTS.tempReport,
  // Light
  ambientLightReport : REPORTS.ambientLightReport,
  ambientLightReport2 : REPORTS.ambientLightReport2,
  // Humidity
  humidityReport : REPORTS.humidityReport,

  // Magnet
  doorReport : REPORTS.doorReport,
  doorCountReport : REPORTS.doorCountReport,

  // User button
  userSwitchAlarm : REPORTS.userSwitchAlarm,

  // Battery
  batteryReport: REPORTS.batteryReport,
};

const drip = {
  // Cap
  floodAlarm : REPORTS.floodAlarm,
  floodReport : REPORTS.floodReport,
  // Temp (partial)
  averageTempAlarm : REPORTS.averageTempAlarm,
  averageTempReport : REPORTS.averageTempReport,
  tempReport : REPORTS.tempReport,
  tempAlarm : REPORTS.tempAlarm,

  // Magnet
  doorReport : REPORTS.doorReport,

  // Battery
  batteryReport: REPORTS.batteryReport,
};

const drip_oil = {
  // Cap
  floodAlarm : REPORTS.floodAlarm,
  floodReport : REPORTS.floodReport,
  oilAlarm : REPORTS.oilAlarm,

  // Temp (partial)
  averageTempAlarm : REPORTS.averageTempAlarm,
  averageTempReport : REPORTS.averageTempReport,
  tempReport : REPORTS.tempReport,
  tempAlarm : REPORTS.tempAlarm,

  // Magnet
  doorReport : REPORTS.doorReport,

  // Battery
  batteryReport: REPORTS.batteryReport,
};


const presence = {
  // IR sensor
  presenceReport : REPORTS.presenceReport,
  closeProximityAlarm : REPORTS.closeProximityAlarm,
  disinfectAlarm  : REPORTS.disinfectAlarm,
  irProximityReport : REPORTS.irProximityReport,

  // Magnet
  userSwitchAlarm : REPORTS.userSwitchAlarm,

  // Temp (partial)
  averageTempReport : REPORTS.averageTempReport,
  tempReport : REPORTS.tempReport,

  // Battery
  batteryReport: REPORTS.batteryReport,
  
};

// Almost all options, whether sensible or not
const multisensor = {
  // Magnet
  doorAlarm : REPORTS.doorAlarm,
  doorReport : REPORTS.doorReport,
  doorCountReport : REPORTS.doorCountReport,
 
  // Temp (partial)
  averageTempAlarm : REPORTS.averageTempAlarm,
  averageTempReport : REPORTS.averageTempReport,
  tempAlarm : REPORTS.tempAlarm,
  tempReport : REPORTS.tempReport,
  // Light
  ambientLightReport : REPORTS.ambientLightReport,
  ambientLightReport2 : REPORTS.ambientLightReport2,
  // Humidity
  humidityReport : REPORTS.humidityReport,
  // User button
  userSwitchAlarm : REPORTS.userSwitchAlarm,
  // Cap
  floodAlarm : REPORTS.floodAlarm,
  floodReport : REPORTS.floodReport,

  // Magnet
  userSwitchAlarm : REPORTS.userSwitchAlarm,
  // Battery
  batteryReport: REPORTS.batteryReport,
};

export default {
  presence,
  guard,
  comfort,
  drip,
  drip_oil,
  multisensor, /* Removed 2021-02-19, Morgan review, Added 2021-06-08 Morgan review */
};
