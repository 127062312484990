// Copyright (C) 2021, Sensative AB (www.sensative.com)

import React from 'react';
import {Provider} from 'react-redux';
import { toast } from 'react-toastify';

import Views from './views';
import store from './state/store';

toast.configure();

const App = () => (
  <Provider store={store}>
    <Views />
  </Provider>
);

export default App;
