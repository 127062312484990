import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import {colors} from '../../views.constants';
import {Button} from '../settings.styled';

const MenuBarContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding-top: 30px;
  display: flex;
  border-bottom: 1px solid ${colors.GREY_2};
`;

const PayloadButton = styled(Button)`
  top: -4px;
  height: 34px;
  margin-left: auto;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  align-items: center;
  padding: 0 0 0 0;
  margin-right: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid transparent;
  background: none;
  height: 40px;
  min-width: 36px;
  color: ${colors.GREY_28};
  text-decoration: none;

  &:hover:enabled {
    color: ${colors.GREY_4};
  }
  &:focus:enabled {
    outline:0;
  }
  &:disabled {
    background: ${colors.GREY_2};
    cursor: default;
  }
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
  &.selected {
    color: ${colors.GRAY3};
    border-width: 4px;
    border-radius: 4px;
    font-weight: 500;
    border-bottom-color: ${colors.MEDIUM_GREEN};
  }
`;

const StyledButtonLink = styled(NavLink)`
  margin-left: auto;
  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const AddedCounter = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  line-height: 16px;
  border-radius: 8px;
  text-align: center;
  background: ${colors.GREY_25};
  font-size: 11px;
  color: ${colors.TRUE_WHITE};
`;

export {
  MenuBarContainer,
  AddedCounter,
  PayloadButton,
  StyledLink,
  StyledButtonLink,
};
