// Copyright (C) 2021, Sensative AB (www.sensative.com)

import {
  SETTINGS_CATEGORIES,
  DEVICES,
  UNITS,
} from '../settings.constants';

// These are arbitrary but will reduce risk of user settting absurd values.
const LOWEST_POLL_INTERVAL = 30;
const LOWEST_SEND_INTERVAL = 600;
const HIGHEST_POLL_INTERVAL = 3600;
const HIGHEST_SEND_INTERVAL = 3600*24*7;

const SETTINGS = {
  // CAP SENSOR
  floodPollInterval: {
    id: 0x18,
    name: 'Flood Poll Interval',
    description: 'How often the unit checks the liquid leak and oil sensors. If set to 0, the sensors will not be checked.',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.minutes,
    coefficient: 60, // Actual representation is seconds
    min: LOWEST_POLL_INTERVAL/60,
    max: HIGHEST_POLL_INTERVAL/60,
    default: 30/60,
    allowZero: true,
  },
  floodReportInterval: {
    id: 0x1b,
    name: 'Flood Report Interval',
    description: 'Determines the interval of which a flood report is sent.',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.minutes,
    coefficient: 60, // Actual representation is seconds
    min: LOWEST_SEND_INTERVAL/60,
    max: HIGHEST_SEND_INTERVAL/60,
    default: 0,
    allowZero: true,
  },
  floodAlarmThreshold: {
    id: 0x1c,
    name: 'Flood Alarm Threshold Level',
    description: 'Amount of liquid the leakage sensor detects before sending a flood alarm.',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.percent,
    min: 0,
    max: 100,
    default: 10,
  },
  floodAlarmHysteresis: {
    id: 0x1d,
    name: 'Flood Alarm Hysteresis',
    description: 'How far below the alarm level the flood value must go for it to send alarm "false" after an alarm has been triggered ("true"). E.g. if your set alarm level is at 40% and you set this value to 10%, then once the flood value goes below 30%, the alarm will send "false".',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.percent,
    min: 0,
    max: 100,
    default: 4,
  },
  // TEMP SENSOR
  tempPollInterval: {
    id: 0x04,
    name: 'Temperature Poll Interval',
    description: 'How often the unit checks the temperature sensor. If set to 0, this sensor will not be checked.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.minutes,
    coefficient: 60, // Actual representation is seconds
    min: LOWEST_POLL_INTERVAL/60,
    max: HIGHEST_POLL_INTERVAL/60,
    default: 2,
    allowZero: true,
  },
  tempSendImmediatelyThreshold: {
    id: 0x05,
    name: 'Temperature Send Immediately Threshold',
    description: 'Sets the temperature range differential (from last reported value) that will trigger a report. E.g. if value is set to 0.5, the unit will send a temperature report when the temperature has changed by +/- 0.5 degrees Celsius. If set to 0, this will not trigger reports.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: 0.1,
    max: 60,
    default: 2,
    allowZero: true,
  },
  tempSendThrottledThreshold: {
    id: 0x06,
    name: 'Temperature Send Throttled Threshold',
    description: 'Sets another threshold for temperature reporting. E.g. If "Temperature Send Threshold" = 2.0 degrees and "Temperature Send Throttled Time" = 1 hour, then a temperature report will not be sent until 1 hour has passed if temperature has change by 2.0 degrees.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: 0.1,
    max: 60,
    default: 1,
    allowZero: true,
  },
  tempSendThrottledTime: {
    id: 0x07,
    name: 'Temperature Send Throttled Time',
    description: 'Sets another time for temperature reporting. E.g. If "Temperature Send Threshold" = 2.0 degrees and "Temperature Send Throttled Time" = 1 hour, then a temperature report will not be sent until 1 hour has passed if temperature has change by 2.0 degrees.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.seconds,
    min: LOWEST_SEND_INTERVAL,
    max: HIGHEST_SEND_INTERVAL,
    default: 3600,
  },
  tempLowAlarmLevel: {
    id: 0x08,
    name: 'Temperature Low Alarm Level',
    description: 'The temperature at which or below the sensor triggers the Temperature Alarm.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: -20,
    max: 120,
    default: -40,
  },
  tempHighAlarmLevel: {
    id: 0x09,
    name: 'Temperature High Alarm Level',
    description: 'The temperature at which or above the sensor triggers the Temperature Alarm.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: -20,
    max: 120,
    default: 120,
  },
  tempAlarmHysteresis: {
    id: 0x0a,
    name: 'Temperature Alarm Hysteresis',
    description: 'How far above or below set high/low alarm level before restarting new alarm triggering. E.g. if your set Temperature alarm level is at 10 degrees, and you set this value to 5, it means after an alarm has been triggered, the alarm will not reset and send again until the value goes either above or below by 5 degrees (15 or 5 degrees Celsius).',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: 0.1,
    max: 60,
    default: 1,
  },
  averageTempTime: {
    id: 0x0b,
    name: 'Average Temperature Time',
    description: 'How often the unit sends average temperature report (sampling frequency of temperature sensor is set by temperature poll interval). If set to 0, this will not trigger reports.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.minutes,
    coefficient: 60, // Actually is seconds
    min: LOWEST_SEND_INTERVAL/60,
    max: HIGHEST_SEND_INTERVAL/60,
    default: 86400/60,
    allowZero: true,
  },
  averageTempSendImmediatelyThreshold: {
    id: 0x0d,
    name: 'Average Temperature Send Immediately Threshold',
    description: 'Sets threshold to send an average temperature report only when average temperature has changed more than set value from last average temperature report. E.g. if value is set to 0.5, the unit will send an average temperature report when the average temperature has changed by +/- 0.5 degrees Celsius. If set to 0, the report will be sent at each interval.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: 0.1,
    max: 60,
    default: 0,
    allowZero: true,
  },
  averageTempAlarmLowLevel: {
    id: 0x0e,
    name: 'Average Temperature Low Alarm Level',
    description: 'At which level the low average temperature alarm should be triggered.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: -20,
    max: 60,
    default: -40,
  },
  averageTempAlarmHighLevel: {
    id: 0x0f,
    name: 'Average Temperature High Alarm Level',
    description: 'At which level the high average temperature alarm should be triggered.',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: -20,
    max: 60,
    default: 120,
  },
  averageTempAlarmHysteresis: {
    id: 0x10,
    name: 'Average Temperature Alarm Hysteresis',
    description: 'Sets hysteresis value to reset average temperature high/low alarm levels. E.g. if your set Average Temperature alarm level is at 10 degrees, and you set this value to 5, it means after an alarm has been triggered, the alarm will not reset and send again until the value goes either above or below by 5 degrees (15 or 5 degrees Celsius).',
    category: SETTINGS_CATEGORIES.temperature,
    unit: UNITS.celsius,
    coefficient: 1000,
    min: 0.1,
    max: 60,
    default: 1,
  },
  // HUM SENSOR
  humPollInterval: {
    id: 0x11,
    name: 'Humidity Poll Interval',
    description: 'How often the unit checks the humidity sensor. If set to 0, this sensor will not be checked.',
    category: SETTINGS_CATEGORIES.humidity,
    unit: UNITS.minutes,
    coefficient: 60, // Actually is seconds
    min: LOWEST_POLL_INTERVAL/60,
    max: HIGHEST_POLL_INTERVAL/60,
    default: 0,
    allowZero: true,
  },
  humThresholdLevel: {
    id: 0x12,
    name: 'Humidity Threshold Level',
    description: 'Triggers a report when last reported value has changed with set value. E.g. if you set the value to 5, the unit will send a humidity report when the value has changed by +/- 5% of the last value reported. If set to 0, this will not trigger reports.',
    category: SETTINGS_CATEGORIES.humidity,
    unit: UNITS.percent,
    min: 1,
    max: 100,
    default: 4,
    allowZero: true,
  },
  // LIGHT SENSOR
  lightPollInterval: {
    id: 0x13,
    name: 'Ambient Light Poll Interval',
    description: 'How often the unit checks the ambient light sensor. If set to 0, this sensor will not be checked.',
    category: SETTINGS_CATEGORIES.light,
    unit: UNITS.minutes,
    coefficient: 60, // Actually is seconds
    min: LOWEST_POLL_INTERVAL/60,
    max: HIGHEST_POLL_INTERVAL/60,
    default: 60/60,
    allowZero: true,
  },
  lightHighLevel1: {
    id: 0x14,
    name: 'Ambient Light High Level 1',
    description: 'Trigger the ambient light report (if enabled) when ambient light level goes above this set value. If no high or low level is set, then reports will occur whenever the lux value has doubled or halved since the last report.',
    category: SETTINGS_CATEGORIES.light,
    unit: UNITS.lux,
    min: 0,
    max: 65535,
    default: 0,
  },
  lightLowLevel1: {
    id: 0x15,
    name: 'Ambient Light Low Level 1',
    description: 'Trigger the ambient light report (if enabled) when ambient light level goes below this set value. If no high or low level is set, then reports will occur whenever the lux value has doubled or halved since the last report.',
    category: SETTINGS_CATEGORIES.light,
    unit: UNITS.lux,
    min: 0,
    max: 65535,
    default: 0,
  },
  lightHighLevel2: {
    id: 0x16,
    name: 'Ambient Light High Level 2',
    description: 'Additional level at which or above Ambient Light Report 2 will be triggered if enabled.',
    category: SETTINGS_CATEGORIES.light,
    unit: UNITS.lux,
    min: 0,
    max: 65535,
    default: 0,
  },
  lightLowLevel2: {
    id: 0x17,
    name: 'Ambient Light Low Level 2',
    description: 'Additional level at which or below Ambient Light Report 2 will be triggered if enabled.',
    category: SETTINGS_CATEGORIES.light,
    unit: UNITS.lux,
    min: 0,
    max: 65535,
    default: 0,
  },
  oilThreshold: {
    id: 0x1e,
    name: 'Oil Threshold',
    description: `Sets the capacitive level for a sensor that triggers an oil alarm. 
    Different surface materials yield different baselines.
    Downlink 030030 (hex)/AwAw (base64) on port 11 to calibrate a mounted unit.`,
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.unitLess,
    min: 0,
    max: 65535,
    default: 1450,
  },
  // MAGNET SENSOR
  doorCountInterval: {
    id: 0x2d,
    name: 'Door Count Interval',
    description: 'Interval of door count reports',
    category: SETTINGS_CATEGORIES.magnet,
    unit: UNITS.minutes,
    coefficient: 60, // Actually is seconds
    min: LOWEST_SEND_INTERVAL/60,
    max: HIGHEST_SEND_INTERVAL/60,
    default: 3600/60,
  },
  // IR PROXIMITY SENSOR
  irProximityPollInterval: {
    id: 0x2e,
    name: 'IR Proximity Poll Interval',
    description: 'How often to check for IR proximity. Diagnostic purposes. Used as poll interval by CloseProximityAlarm before 2021.',
    category: SETTINGS_CATEGORIES.presence,
    unit: UNITS.seconds,
    min: LOWEST_POLL_INTERVAL/2,
    max: 3600,
    default: 120,
    hidden: true,
  },
  presencePollInterval: {
    id: 0x2f,
    name: 'Presence Poll Interval',
    description: 'How often to check for movements. Recommend 60+ s for person detection,  30+ s for object detection',
    category: SETTINGS_CATEGORIES.presence,
    unit: UNITS.seconds,
    min: LOWEST_POLL_INTERVAL/2,
    max: 180,
    default: 120,
  },
  presenceThreshold: {
    id: 0x30,
    name: 'Presence Threshold',
    description: 'How much movement is needed to change state. Increase the value if you get false positives. Decrease if it is not sensitive enough.',
    category: SETTINGS_CATEGORIES.presence,
    unit: UNITS.unitLess,
    min: 1,
    max: 10000,
    default: 30,
    // hidden: true, LM - removed since it makes it impossible to adjust 
  },
  presenceTimeout: {
    id: 0x31,
    name: 'Presence Timeout',
    description: 'For how long time no movement should be detected until determining that there is no presence. Recommended to be minimum 900s (15 minutes). Should exceed 10 times the poll interval.',
    category: SETTINGS_CATEGORIES.presence,
    unit: UNITS.minutes,
    coefficient: 60, // Actually is seconds
    min: LOWEST_SEND_INTERVAL/60,
    max: HIGHEST_SEND_INTERVAL/60,
    default: 3600/60,
  },
  doorAlarmInvert: {
    id: 0x32,
    name: 'Door Alarm Invert',
    description: 'Set this to 1 to invert the behaviour of the door sensor, e.g. a magnet present indicates open instead of closed.',
    category: SETTINGS_CATEGORIES.magnet,
    unit: UNITS.unitLess,
    min: 0,
    max: 1,
    default: 0,
  },
  oilFilterFactor: {
    id: 0x37,
    name: 'Oil Filter Factor',
    description: 'Low pass filter coefficient for detection of deviation vs moving average. Zero disables this detection type. Recommended range: 5-15',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.unitLess,
    min: 0,
    max: 30,
    default: 0,
    allowZero: true,
  },
  oilHysteresis: {
    id: 0x38,
    name: 'Oil Sensor Differential',
    description: 'By how much does cap value have to deviate from filtered value to trigger differential based alarm.',
    category: SETTINGS_CATEGORIES.leakage,
    unit: UNITS.unitLess,
    min: 1,
    max: 100,
    default: 30,
  },
  objectDetectCount: {
    id: 0x3a,
    name: 'Object Detect Oversampling Count',
    description: 'How many times in a row must an object must be detected to be present / absent.',
    category: SETTINGS_CATEGORIES.presence,
    unit: UNITS.unitLess,
    min: 1,
    max: 100,
    default: 1,
  },
  // General
  ledLevel: {
    id: 0x29,
    name: 'LED Level',
    description: 'LED Behaviour control: 0 - Very limited LED, 1 - User interaction and errors, 2 - All events',
    category: SETTINGS_CATEGORIES.general,
    unit: UNITS.unitLess,
    min: 0,
    max: 2,
    default: 2,
//    hidden: true,
  },
  basePollInterval: {
    id: 0x02,
    name: 'Magnet Poll Interval',
    description: `The interval at which the unit will check magnet sensors and which all other intervals are multiples of. 
    Directly affects battery life: A lower interval will make the device more responsive to magnets and user interaction but will also decrease battery life.`,
    category: SETTINGS_CATEGORIES.general,
    unit: UNITS.seconds,
    coefficient: 10,
    min: 1,
    max: 10,
    default: 1.5,
//    hidden: true,
  },
  sensorConfiguration: {
    id: 0x32,
    name: 'Sensor Configuration',
    description: `Enables special report functionalities`,
    category: SETTINGS_CATEGORIES.general,
    unit: UNITS.unitLess,
    min: 0,
    max: 1024,
    default: 0,
//    hidden: true,
  },
  linkcheckInterval: {
    id: 0x2A,
    name: 'Linkcheck Interval',
    description: `Linkcheck Interval`,
    category: SETTINGS_CATEGORIES.general,
    unit: UNITS.hours,
    min: 0,
    max: 168,
    default: 24,
    hidden: true,
  },
  /* loraDataRate: {
    id: 0x28,
    name: 'LoRa Data Rate',
    description: `Sets Strips to report on a fixed data rate. Set to 511 to enable Adaptive Data Rate (ADR), which is the default setting.`,
    category: SETTINGS_CATEGORIES.general,
    unit: UNITS.dataRate,
    min: 0,
    max: 5,
    default: 5,
    exemptedValue: 511,
//    hidden: true,
  },*/
}

// These settings are available for user to set for the multisensor
// Removed feb 19 2021, Morgans review
// Added again, jun 8 2021, Morgans review
const multisensor = [
  // Door magnet
  SETTINGS.doorCountInterval,
  SETTINGS.doorAlarmInvert,

  // Flood
  SETTINGS.floodPollInterval,
  SETTINGS.floodReportInterval,
  SETTINGS.floodAlarmThreshold,
  SETTINGS.floodAlarmHysteresis,

  // Temp (complete)
  SETTINGS.tempPollInterval,
  SETTINGS.tempSendImmediatelyThreshold,
  SETTINGS.tempSendThrottledThreshold,
  SETTINGS.tempSendThrottledTime,
  SETTINGS.tempLowAlarmLevel,
  SETTINGS.tempHighAlarmLevel,
  SETTINGS.tempAlarmHysteresis,
  SETTINGS.averageTempTime,
  SETTINGS.averageTempSendImmediatelyThreshold,
  SETTINGS.averageTempAlarmLowLevel,
  SETTINGS.averageTempAlarmHighLevel,
  SETTINGS.averageTempAlarmHysteresis,

  // Light
  SETTINGS.lightPollInterval,
  SETTINGS.lightLowLevel1,
  SETTINGS.lightHighLevel1,
  SETTINGS.lightLowLevel2,
  SETTINGS.lightHighLevel2,

  // Humidity
  SETTINGS.humPollInterval,
  SETTINGS.humThresholdLevel,

  // Oil or Removal
  SETTINGS.oilThreshold, // oilAlarm
  SETTINGS.oilFilterFactor,
  SETTINGS.oilHysteresis,

  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  //SETTINGS.loraDataRate,
];

// These settings are available for user to set for the guard
const guard = [
  SETTINGS.doorCountInterval,
  SETTINGS.tempLowAlarmLevel,
  SETTINGS.tempHighAlarmLevel,
  SETTINGS.tempAlarmHysteresis,
  SETTINGS.doorAlarmInvert,
  SETTINGS.tempPollInterval,

  // Light
  SETTINGS.lightPollInterval,
  SETTINGS.lightLowLevel1,
  SETTINGS.lightHighLevel1,
  SETTINGS.lightLowLevel2,
  SETTINGS.lightHighLevel2,

  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  SETTINGS.linkcheckInterval,
  //SETTINGS.loraDataRate,
];

// These settings are available for user to set for the guard lite
const guard_lite = [
  SETTINGS.doorCountInterval,
  SETTINGS.doorAlarmInvert,
  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  //SETTINGS.loraDataRate,
];

// These settings are available for user to set for the drip
const drip = [
  // Flood
  SETTINGS.floodPollInterval,
  SETTINGS.floodReportInterval,
  SETTINGS.floodAlarmThreshold,
  SETTINGS.floodAlarmHysteresis,

  // Temp (complete)
  SETTINGS.tempPollInterval,
  SETTINGS.tempSendImmediatelyThreshold,
  SETTINGS.tempSendThrottledThreshold,
  SETTINGS.tempSendThrottledTime,
  SETTINGS.tempLowAlarmLevel,
  SETTINGS.tempHighAlarmLevel,
  SETTINGS.tempAlarmHysteresis,
  SETTINGS.averageTempTime,
  SETTINGS.averageTempSendImmediatelyThreshold,
  SETTINGS.averageTempAlarmLowLevel,
  SETTINGS.averageTempAlarmHighLevel,
  SETTINGS.averageTempAlarmHysteresis,

  // Light
  SETTINGS.lightPollInterval,
  SETTINGS.lightLowLevel1,
  SETTINGS.lightHighLevel1,
  SETTINGS.lightLowLevel2,
  SETTINGS.lightHighLevel2,

  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  //SETTINGS.loraDataRate,
];

const drip_oil = [
  ...drip,
  // Oil or Removal
  SETTINGS.oilThreshold, // oilAlarm
  SETTINGS.oilFilterFactor,
  SETTINGS.oilHysteresis,

  // General
  //SETTINGS.loraDataRate,
];

// These settings are available for user to set for the comfort sensor
const comfort = [
  // Temp (complete)
  SETTINGS.tempPollInterval,
  SETTINGS.tempSendImmediatelyThreshold,
  SETTINGS.tempSendThrottledThreshold,
  SETTINGS.tempSendThrottledTime,
  SETTINGS.tempLowAlarmLevel,
  SETTINGS.tempHighAlarmLevel,
  SETTINGS.tempAlarmHysteresis,
  SETTINGS.averageTempTime,
  SETTINGS.averageTempSendImmediatelyThreshold,
  SETTINGS.averageTempAlarmLowLevel,
  SETTINGS.averageTempAlarmHighLevel,
  SETTINGS.averageTempAlarmHysteresis,

  // Humidity
  SETTINGS.humPollInterval,
  SETTINGS.humThresholdLevel,

  // Light
  SETTINGS.lightPollInterval,
  SETTINGS.lightLowLevel1,
  SETTINGS.lightHighLevel1,
  SETTINGS.lightLowLevel2,
  SETTINGS.lightHighLevel2,

  // Magnet
  SETTINGS.doorCountInterval,
  SETTINGS.doorAlarmInvert,

  // Removal alarm
  SETTINGS.removalThreshold,

  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  //SETTINGS.loraDataRate,
];

// These settings are available for the user to set for the presence sensor
const presence = [
  SETTINGS.irProximityPollInterval,
  SETTINGS.presencePollInterval,
  SETTINGS.presenceTimeout,
  SETTINGS.presenceThreshold,
  SETTINGS.objectDetectCount,

  // General
  SETTINGS.ledLevel,
  SETTINGS.basePollInterval,
  //SETTINGS.loraDataRate,
];

const PRODUCT_SETTINGS = {
  /* multisensor, Removed feb 19 2021, Morgans review */
  comfort,
  drip,
  guard,
  presence,
  drip_oil,
  multisensor, /* multisensor - added again jun 8, 2021, Morgans review */
};

export {
  PRODUCT_SETTINGS,
  SETTINGS_CATEGORIES,
  DEVICES,
  SETTINGS,
};
