import styled from 'styled-components';
import React from 'react';
import {Icon} from 'react-icons-kit';
import {ic_info_outline as infoIcon} from 'react-icons-kit/md/ic_info_outline'

import {colors} from '../../views.constants';

import {
  TableCell,
  TableHeadingCell,
} from '../settings.styled';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
`;

const SelectHeading = styled.h2`
  font-size: 13px;
  font-weight: 500;
  margin: 15px 0 7px 3px;
`;

const DescriptionContainer = styled.div`
  border-top: 1px solid #ccc;
  padding-top: 25px;
  margin: 30px 0 30px 0;
  h4 {
    margin: 0 0 8px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
  }
`;

const TableContainer = styled.div`
  margin: 0 0 30px 0;
  h4 {
    margin: 0 0 8px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
  }
`;

const SettingsTableHeadingCell = styled(TableHeadingCell)`
  &:nth-child(2n+1) {
    padding-left: 12px;
    border-left-width: 1px;
    border-radius: 4px 0 0 0;
  }
  &:nth-child(2n) {
    border-right-width: 1px;
    border-radius: 0 4px 0 0;
    padding-right: 12px;
    justify-content: flex-start;
  }
`;

const SettingsTableCell = styled(TableCell)`
  &:nth-child(2n+1) {
    border-left-width: 1px;
    padding-left: 12px;
  }
  &:nth-child(2n) {
    border-right-width: 1px;
    padding-right: 12px;
    justify-content: flex-start;
  }
`;

const ReportsTableHeadingCell = styled(TableHeadingCell)`
  border-right-width: 1px;
  border-left-width: 1px;
  border-radius: 4px 4px 0 0;
  padding-left: 12px;
`;

const ReportsTableCell = styled(TableCell)`
  border-right-width: 1px;
  border-left-width: 1px;
  padding-left: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 0;
  padding: 20px 0 0 0;
  border-top: 1px solid #ccc;
`;

const NoProfilesNote = styled.div`
  text-align: center;
  margin-top: 50px;
  color: ${colors.GREY_27};
`;

const AddedSettingIconContainer = styled.div`
  display: flex;
  color: ${colors.MEDIUM_GREEN};
  p {
    margin-left: 6px;
    position: relative;
    top: 2px;
    font-weight: 600;
  }
`;

const InfoNote = props => (
  <InfoNoteContainer>
    <Icon icon={infoIcon} size={18} />
    <p>
      {props.children}
    </p>
  </InfoNoteContainer>
);

const InfoNoteContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  color: ${colors.GREY_28}
  p {
    margin-left: 5px;
  }
`;

export {
  Wrapper,
  SelectHeading,
  InfoNote,
  DescriptionContainer,
  TableContainer,
  SettingsTableHeadingCell,
  SettingsTableCell,
  ReportsTableHeadingCell,
  ReportsTableCell,
  ButtonsContainer,
  NoProfilesNote,
  AddedSettingIconContainer,
};
