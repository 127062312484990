import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {blue} from '@material-ui/core/colors';
import {Icon} from 'react-icons-kit';
import {ic_info_outline as infoIcon} from 'react-icons-kit/md/ic_info_outline'
import MaterialTooltip from '@material-ui/core/Tooltip';

import {colors} from '../views.constants';

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  flex: 1;
  padding-top: 50px;
`;

const Input = styled.input`
  width: 98%;
  height: 34px;
  border: 1px solid ${colors.GREY_2};
  border-radius: 4px;
  padding: 0 1%;
  font-size: 14px;
  color: ${colors.GREY_3};
  &:focus {
    outline: 0;
    border-color: ${colors.WHITE};
    box-shadow: 0 0 0 2px ${colors.BLUE};
  }
  &:disabled {
    background: ${colors.GREY_02}
  }
`;

const Tooltip = props => {
  if (!props.title) return props.children;
  return (
    <MaterialTooltip
      title={props.title}
      placement={props.placement}
      enterDelay={props.delay}
    >
      <span>{props.children}</span>
    </MaterialTooltip>
  );
};

const Button = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: ${props => props.fontSize || 15}px;
  align-items: center;
  padding-left: ${props => props.sidePadding || 20}px;
  padding-right: ${props => props.sidePadding || 20}px;
  border-radius: ${props => props.borderRadius || `4px`};
  border: ${props => props.border || 'none'};
  height: ${props => props.height || 30}px;
  margin-left: ${props => props.marginLeft || '0'};
  min-width: 36px;
  color: ${props => props.color || colors.TRUE_WHITE};
  background: ${props => props.background || colors.MEDIUM_GREEN};
  transition: color 0.2s, background 0.2s, all 0.2s;

  &:hover:enabled {
    transition: color 0.2s, background 0.2s, all 0.2s;
    filter: brightness(110%);
  }
  &:focus:enabled {
    outline:0;
  }
  &:disabled {
    ${({ghosted}) => !ghosted && `
    background: ${colors.GREY_2};
    `}
    cursor: default;
  }
`;

const GhostedButton = props => (
  <Button
    border={`1px solid ${props.disabled ? colors.GREY_25 : colors.RED}`}
    background={'none'}
    color={props.disabled ? colors.GREY_25 : colors.RED}
    sidePadding={props.sidePadding}
    marginLeft={props.marginLeft}
    height={props.height}
    fontSize={props.fontSize}
    onClick={props.onClick}
    disabled={props.disabled}
    ghosted
  >
    {props.children}
  </Button>
);

const SmallGhostedButton = props => (
  <GhostedButton
    sidePadding={10}
    height={24}
    fontSize={13}
    onClick={props.onClick}
  >
    {props.children}
  </GhostedButton>
);

const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: blue[700],
    },
    '&$checked + $track': {
      backgroundColor: blue[700],
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledLink = styled(Link)`
  color: ${colors.DARK_GREEN};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns || '1 fr'};
  grid-auto-rows: min-content;
  font-size: 13px;
`;

const TableCell = styled.div`
  display:flex;
  align-items: center;
  border-style: solid;
  border-color: ${colors.GREY_15};
  border-width: 0 0 1px 0;
  min-height: 40px;
  font-size: 13px;
  background: white;
`;

const TableHeadingCell = styled(TableCell)`
  background: ${colors.GREY_0};
  min-height: 32px;
  line-height: 32px;
  font-size: 12px;
  border-top-width: 1px;
`;

const TooltipBadge = styled.div`
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 7px;
  font-size: 11px;
  font-weight: bold;
  background: ${colors.GREY_15};
  margin-left: 7px;
  text-align: center;
  cursor: default;
`;

const InfoBox = props => (
  <InfoBoxContainer>
    <Icon icon={infoIcon} size={48} />
    <InfoBoxContent>
      {props.children}
    </InfoBoxContent>
  </InfoBoxContainer>
);

const InfoBoxContainer = styled.div`
  width: 96%;
  display: flex;
  background: #f2f7fc;
  border: 1px solid #7da3bc;
  border-radius: 4px;
  padding: 25px 2% 25px 2%;
  margin-bottom: 30px;
  color: #7da3bc;
  align-items: center;

  h3 {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 14px;
  }
  p {
    padding: 5px 0;
    line-height: 1.4;
  }
`;

const InfoBoxContent = styled.div`
  padding-left: 20px;
  color: ${colors.GREY_3};
`;

export {
  SettingsWrapper,
  Input,
  Tooltip,
  Button,
  GhostedButton,
  SmallGhostedButton,
  StyledSwitch,
  StyledLink,
  StyledTable,
  TableHeadingCell,
  TableCell,
  TooltipBadge,
  InfoBox,
};
