import _ from 'lodash';

const actionTypes = {
  SELECT_STEP: 'SELECT_STEP',
  SELECT_DEVICE: 'SELECT_DEVICE',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  SELECT_SETTING: 'SELECT_SETTING',
  SELECT_PROFILE: 'SELECT_PROFILE',
  UPDATE_NEW_VALUE: 'UPDATE_NEW_VALUE',
  SHOW_SETTINGS_VIEW: 'SHOW_SETTINGS_VIEW',
  TOGGLE_BASE64_ENCODING: 'TOGGLE_BASE64_ENCODING',
  TOGGLE_SHOW_ADVANCED: 'TOGGLE_SHOW_ADVANCED',
};

const actions = {
  setSelectedStep: step => dispatch => {
    dispatch({
      type: actionTypes.SELECT_STEP,
      payload: {step},
    })
  },
  selectDevice: device => dispatch => {
    dispatch({
      type: actionTypes.SELECT_DEVICE,
      payload: {device}
    });
  },
  selectCategory: category => dispatch => {
    dispatch({
      type: actionTypes.SELECT_CATEGORY,
      payload: {category}
    });
  },
  selectSetting: (setting, addedSettings) => dispatch => {
    dispatch({
      type: actionTypes.SELECT_SETTING,
      payload: {setting, addedSettings}
    });
  },
  selectProfile: profile => dispatch => {
    dispatch({
      type: actionTypes.SELECT_PROFILE,
      payload: {profile}
    });
  },
  updateNewValue: value => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_NEW_VALUE,
      payload: {value}
    });
  },
  toggleBase64Encoding: () => dispatch => {
    dispatch({type: actionTypes.TOGGLE_BASE64_ENCODING});
  },
  setShowAdvanced: b => dispatch => {
    dispatch({type: actionTypes.TOGGLE_SHOW_ADVANCED, payload: {b}});
  },
};

const defaultState = {
  selectedStep: 0, // Which step are we on in the wizard 0-3
  selectedDevice: null,
  selectedCategory: null,
  selectedSetting: null,
  selectedProfile: null,
  newValue: '',
  base64encodingIsEnabled: false,
  showAdvanced: false,
};

const reduce = (state = defaultState, action) => {
  switch(action.type) {
    case actionTypes.SELECT_STEP: {
      const {step} = action.payload;
      return {
        ...state,
        selectedStep: step,
      }
    }
    case actionTypes.SELECT_DEVICE: {
      const {device} = action.payload;
      return {
        ...state,
        selectedStep: 0,
        selectedDevice: device,
        selectedCategory: defaultState.selectedCategory,
        selectedSetting: defaultState.selectedSetting,
        newValue: defaultState.newValue,
        selectedProfile: defaultState.selectedProfile,
      }
    }
    case actionTypes.SELECT_CATEGORY: {
      const {category} = action.payload;
      return {
        ...state,
        selectedCategory: category,
        selectedSetting: defaultState.selectedSetting,
        newValue: defaultState.newValue,
      }
    }
    case actionTypes.SELECT_SETTING: {
      const {setting, addedSettings} = action.payload;
      const value = _.reduce(addedSettings, (p, s) => (s.setting === setting) ? s.value : p, defaultState.newValue);
      return {
        ...state,
        selectedSetting: setting ? setting : defaultState.selectedSetting,
        newValue: setting ? value : defaultState.newValue,
      }
    }
    case actionTypes.SELECT_PROFILE: {
      const {profile} = action.payload;
      return {
        ...state,
        selectedProfile: profile,
      }
    }
    case actionTypes.UPDATE_NEW_VALUE: {
      const {value} = action.payload;
      return {...state, newValue: value.trim()}
    }
    case actionTypes.TOGGLE_BASE64_ENCODING: {
      return {...state, base64encodingIsEnabled: !state.base64encodingIsEnabled}
    }
    case actionTypes.TOGGLE_SHOW_ADVANCED: {
      const {b} = action.payload;
      return {...state, showAdvanced: b}
    }
    default: {
      return state;
    }
  }
};

export {
  actions,
  reduce,
};
