import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

import {Container} from './select-device.styled';
import {DEVICES} from '../settings.constants';

const SelectDevice = props => (
  <Container>
    <h2>Device model to configure:</h2>
    <Select
      options={_.values(DEVICES)}
      value={DEVICES[props.selectedDevice]}
      onChange={evt => props.selectDevice(evt.value)}
      isSearchable={false}
      placeholder="Select device..."
    />
  </Container>
);

export default SelectDevice;
