import {water as leakageIcon} from 'react-icons-kit/entypo/water';
import {thermometer3 as temperatureIcon} from 'react-icons-kit/fa/thermometer3';
import {tools as toolsIcon} from 'react-icons-kit/entypo/tools';
import {air as humidityIcon} from 'react-icons-kit/entypo/air';
import {ic_wb_incandescent as lightIcon} from 'react-icons-kit/md/ic_wb_incandescent';
import {male as presenceIcon} from 'react-icons-kit/fa/male';
import {magnet as magnetIcon} from 'react-icons-kit/fa/magnet';
import {toggleOff as switchIcon} from 'react-icons-kit/fa';
import {close as removalIcon} from 'react-icons-kit/fa';

// Selecting which view to use. A bit awkward since combined with routes, but not wantiing to create MW to manage routes explicitly in redux.
const STEP_ID_PROFILES = 0;
const STEP_ID_REPORTS  = 1;
const STEP_ID_SETTINGS = 2;
const STEP_ID_PAYLOAD  = 3;

const STEPS = {
  SECOND:    STEP_ID_REPORTS, // Shortcut to second step, used when a profile was selected
  PROFILES:  STEP_ID_PROFILES,
  REPORTS:   STEP_ID_REPORTS,
  SETTINGS:  STEP_ID_SETTINGS,
  PAYLOAD:   STEP_ID_PAYLOAD,
  routeToStep : {
    '/'         : STEP_ID_PROFILES,
    '/profiles' : STEP_ID_PROFILES,
    '/reports'  : STEP_ID_REPORTS,
    '/settings' : STEP_ID_SETTINGS,
    '/payload'  : STEP_ID_PAYLOAD,
  },
  // NOTE: Must be aligned with the STEPS above
  stepToRoute : {
    0: '/profiles',
    1: '/reports',
    2: '/settings',
    3: '/payload',
  }
}

const SETTINGS_CATEGORIES = {
  temperature: {
    name: 'Temperature',
    icon: temperatureIcon,
  },
  magnet: {
    name: 'Magnet',
    icon: magnetIcon,
  },
  humidity: {
    name: 'Humidity',
    icon: humidityIcon,
  },
  light: {
    name: 'Light',
    icon: lightIcon,
  },
  leakage: {
    name: 'Leakage',
    icon: leakageIcon,
  },
  presence: {
    name: 'Presence',
    icon: presenceIcon,
  },
  button: {
    name: 'Switch',
    icon: switchIcon,
  },
  tampering: {
    name: 'Tampering',
    icon: removalIcon,
  },
  general: {
    name: 'General Settings',
    icon: toolsIcon,
  }
};

const DEVICES = {
/*  guard_lite: {
    value: 'guard_lite',
    label: 'Strips +Guard',
    categories: [
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.tampering,
    ],
  }, */
  drip_oil: {
    value: 'drip_oil',
    label: 'Strips Multi-sensor +Oil',
    categories: [
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.leakage,
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.general,
    ],
  },
  guard: {
    value: 'guard',
    label: 'Strips Multi-sensor +Guard',
    categories: [
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.tampering,
      SETTINGS_CATEGORIES.light,
      SETTINGS_CATEGORIES.general,
    ],
  },
  drip: {
    value: 'drip',
    label: 'Strips Multi-sensor +Drip',
    categories: [
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.leakage,
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.light,
      SETTINGS_CATEGORIES.general,
    ],
  },
  comfort: {
    value: 'comfort',
    label: 'Strips Multi-sensor +Comfort',
    categories: [
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.light,
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.humidity,
      SETTINGS_CATEGORIES.button,
      SETTINGS_CATEGORIES.tampering,
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.general,
    ],
  },
  presence: {
    value: 'presence',
    label: 'Strips Multi-sensor +Presence',
    categories: [
      SETTINGS_CATEGORIES.presence,
      SETTINGS_CATEGORIES.button,
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.general,
    ]
  },
  /* Removed 210219, Morgan review,
     Added 210607, Morgan review, */
  multisensor: {
    value: 'multisensor',
    label: 'Strips Multi-sensor (Advanced configuration)',
    categories: [
      SETTINGS_CATEGORIES.temperature,
      SETTINGS_CATEGORIES.leakage,
      SETTINGS_CATEGORIES.light,
      SETTINGS_CATEGORIES.magnet,
      SETTINGS_CATEGORIES.button,
      SETTINGS_CATEGORIES.tampering,
      SETTINGS_CATEGORIES.general,
    ],
  },
};

const UNITS = {
  hours: {
    fullName: 'Hours',
    shortName: 'hr',
  },
  minutes: {
    fullName: 'Minutes',
    shortName: 'min',
  },
  seconds: {
    fullName: 'Seconds',
    shortName: 's',
  },
  celsius: {
    fullName: 'Celsius',
    shortName: '°C',
  },
  percent: {
    fullName: '%',
    shortName: '%',
  },
  lux: {
    fullName: 'Lux',
    shortName: 'lux',
  },
  unitLess: {
    fullName: 'Unitless',
    shortName: '',
  }
  //dataRate: {
  //  fullName: 'Data Rate',
  //  shortName: '',
  //}
};

const INSTRUCTIONS = [
  'Copy the payload above and send it as a down-link on port 11, set confirmed flag if you want to be sure the unit received the settings.',
  'Downlink is normally transferred within 24hrs. Otherwise force receive by moving a magnet over the rounded edge 3 times for 3 LED blinks',
];

const REPORT_TYPES = {
  enabledReports: {
    name: 'enabledReports',
    prettyName: 'Enabled Reports',
    id: '03',
  },
  combinedReportsOne: {
    name: 'combinedReportsOne',
    prettyName: 'Report Group 1',
    id: '22',
  },
  combinedReportsTwo: {
    name: 'combinedReportsTwo',
    prettyName: 'Report Group 2',
    id: '23',
  },
  combinedReportsThree: {
    name: 'combinedReportsThree',
    prettyName: 'Report Group 3',
    id: '24',
  },
  confirmedReports: {
    name: 'confirmedReports',
    prettyName: 'Confirmed Reports',
    id: '39',
  },
};

const RELATED_REPORTS_TOOLTIP = 'These reports are affected by this setting';
const COMBINED_REPORTS_EXPLANATION = 'Combine selected reports a into single uplink payload. When any enabled report in the RG triggers, each value in the RG will be included into one combined uplink payload. Three RGs are available. Aggregated reports (such as Presence and Average Temp) should not be included in RGs without being enabled.';
const ENABLE_REPORT_EXPLANATION = 'Enable the device to trigger the report.';

const PAYLOAD_PREFIX = '01';

export {
  DEVICES,
  SETTINGS_CATEGORIES,
  UNITS,
  PAYLOAD_PREFIX,
  INSTRUCTIONS,
  RELATED_REPORTS_TOOLTIP,
  ENABLE_REPORT_EXPLANATION,
  COMBINED_REPORTS_EXPLANATION,
  REPORT_TYPES,
  STEPS,
};
