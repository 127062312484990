import styled from 'styled-components';

import {colors} from './views.constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${colors.WHITE};
  color: ${colors.GREY_3};
  font-size: 14px;
`;

export {
  Wrapper,
};
