import styled from 'styled-components';
import {colors} from '../../views.constants';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  margin-top: 30px;
  padding: 20px 16px 16px 16px;
  background: ${colors.GREY_0};
  border: 1px solid ${colors.GREY_15};
  border-radius: 4px;
  h2 {
    font-size: 13px;
    margin: 0 0 6px 4px;
    padding: 0;
    font-weight: 500;
  }
`;

export {
  Container,
};
