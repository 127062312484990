import React from 'react';
import _ from 'lodash';
import Select from 'react-select';

import {PRODUCT_SETTINGS, DEVICES } from '../../static-data';
import {SelectSettingWrapper} from './select-setting.styled';
import {StyledCheckbox, } from '../../enable-reports/enable-reports.styled';
import {colors} from '../../../views.constants';
import {SettingsTableSection} from '../../generate-payload';
import {STEPS} from '../../settings.constants';
import { Container } from '../../select-device/select-device.styled';

import {
  StyledLink,
  InfoBox,
  Button,
} from '../../settings.styled';
import { ButtonsContainer } from '../../profiles/profiles.styled'

const SelectSetting = props => {
  const showAdvanced = props.showAdvanced;

  const getSelectedCategoryValue = () => {
    if (!props.selectedCategory) return null;
    return {label: props.selectedCategory, value: props.selectedCategory}
  };

  const getSelectedSettingValue = () => {
    if (!props.selectedSetting) return null;
    return {label: props.selectedSetting.name, value: props.selectedSetting.id}
  };

  const getSelectCategoryOptions = () => {
    return _.map(DEVICES[props.selectedDevice].categories, category => {
      let addedSettingsObjects = [];
      _.map(props.addedSettings, (item) => addedSettingsObjects.push(item.setting));
      const numSettingsAdded = _.reduce(addedSettingsObjects, (result, setting) => {
        if (setting.category.name === category.name) result++;
        return result;
      }, 0);
      let label = category.name;
      if (numSettingsAdded) {
        label += ` (${numSettingsAdded} added)`;
      }
      return {label, value: category.name}
    });
  };

  const getSelectSettingOptions = () => {
    const filteredSettings = _.pickBy(PRODUCT_SETTINGS[props.selectedDevice], setting => {
      return setting && setting.category.name === props.selectedCategory && !setting.hidden;
    });
    return _.map(filteredSettings, (setting) => {
      let label = setting.name;
      let isSet = _.reduce(props.addedSettings, (prev, added) => added.setting === setting ? true : prev, false); 
      if (isSet) {
        label = ' ** '+ label + ' (added)';
      }
      return {label, value: setting};
    });
  };

  const Buttons = () => (
    <ButtonsContainer>
      {
        <Button onClick={() => props.setSelectedStep(STEPS.PAYLOAD)}>Proceed</Button>
      }
    </ButtonsContainer>
  );

  return (
    <SelectSettingWrapper>
      <Container>
       { props.numAddedReports === 0 && <InfoBox>
        No reports are enabled. Check your <StyledLink to="/profiles" onClick={()=>props.setSelectedStep(STEPS.PROFILES)}>Profile</StyledLink>, or proceed to create a downlink that just changes your settings
        </InfoBox>}
        { props.numAddedReports !== 0 && (props.usedProfile) && _.keys(props.addedSettings).length === 0 && <InfoBox>Using default settings</InfoBox>}

      <SettingsTableSection
          addedSettings={props.addedSettings}
          addedReports={props.addedReports}
          removeSetting={props.removeSetting}
          selectedDevice={props.selectedDevice}
          selectCategory={props.selectCategory}
          selectSetting={props.selectSetting}
          combinedReportsWarnings={props.combinedReportsWarnings}
          usedProfile={props.usedProfile}
          reset={props.reset}
          showAdvanced={props.showAdvanced}
          showEdit={true}
      />
      <Buttons/>
      </Container>
      <div style={{marginTop:8, padding:16, backgroundColor:colors.GREY_02}}>
      <h4>Modify or Add Settings</h4>
      <InfoBox>Modifying or adding settings to values other than the ones in the selected profile may significantly affect product battery life, performance, and function.</InfoBox>
      {<table style={{paddingBottom:'10pt'}}><tbody >
        <tr><td><StyledCheckbox size={15} color={colors.DARK_GREEN} checked={showAdvanced} onClick={() => props.setShowAdvanced(!showAdvanced)}/></td><td>I understand the above. Show advanced settings.</td></tr>
      </tbody></table>}

      {showAdvanced && <React.Fragment><h2>Select settings category</h2>
      <Select
        options={getSelectCategoryOptions()}
        value={getSelectedCategoryValue()}
        onChange={evt => props.selectCategory(evt.value)}
        placeholder="Select category..."
        isSearchable={false}
      />
      <h2>Select setting</h2>
      <Select
        options={getSelectSettingOptions()}
        value={getSelectedSettingValue()}
        onChange={evt => props.selectSetting(evt.value, props.addedSettings)}
        isDisabled={!props.selectedCategory}
        placeholder="Select setting..."
        styles={{
          control: provided => ({
            ...provided,
            marginTop: '0px',
            borderColor: colors.GREY_2,
          }),
        }}
        isSearchable={false}
      /></React.Fragment>}
      </div>
    </SelectSettingWrapper>
  );
};

export default SelectSetting;
